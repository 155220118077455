<template>
  <v-file-input
    v-model="file"
    accept=".xlsx,.csv"
    :label="props.label"
    :disabled="props.disabled"
    :error-messages="errors"
    @change="onFileChange"
    flat
    ><slot
  /></v-file-input>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  value: { type: Object, default: null },
  label: { type: String, default: 'Select an XLSX or CSV file' },
  disabled: { type: Boolean, default: false },
  errors: { type: Array, default: () => [] }
})
const emit = defineEmits(['change'])

const file = ref(null)
const onFileChange = (val) => {
  emit('change', val)
}
</script>
