<template>
  <app-form-view
    app="audit"
    model="task"
    api-url="/task/task/"
    :title="$t('menu.section')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
    @input="formLoaded"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.sectionName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="taskTemplate"
            rules="required"
            type="select-server"
            :label="$t('fields.taskTemplate')"
            :view="view"
            :binds="{
              apiUrl: 'task/task-template-header/?active=true'
            }"
            v-model="formData.task_template_header_id"
            ref="task_template_header"
            :events="{
              change: onTaskTemplateChange
            }"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTask"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_task"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="contactID"
            type="select-server"
            :label="$t('fields.contactName')"
            :view="view"
            :disabled="true"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.contact_id"
            ref="contact"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContactID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="parentTask"
            type="select-server"
            :label="$t('fields.parentTask')"
            :view="view"
            :binds="{
              apiUrl: parentTaskUrl
            }"
            v-model="formData.parent_task_id"
            ref="parent_task"
            :disabled="isParentTaskDisabled"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePatentTaskId"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_parent_task_id"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="sequence"
            rules="required"
            type="number"
            :view="view"
            :label="$t('fields.seq')"
            v-model="formData.sequence"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveSequence"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_sequence"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reference"
            :isUpperCase="true"
            :view="view"
            :label="$t('fields.reference')"
            v-model="formData.reference"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReference"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_reference"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditYear"
            type="year-selection"
            :label="$t('fields.auditYear')"
            :view="view"
            :disabled="true"
            v-model="formData.audit_year"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditYear"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_year"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPeriod"
            type="select"
            :label="$t('fields.auditPeriod')"
            :view="view"
            :disabled="true"
            v-model="formData.audit_period"
            :items="[
              {
                label: 'Q1',
                value: 'q1'
              },
              {
                label: 'Q2',
                value: 'q2'
              },
              {
                label: 'Q3',
                value: 'q3'
              },
              {
                label: 'Q4',
                value: 'q4'
              },
              { label: 'Year End', value: 'year_end' },
              {
                label: 'Customs',
                value: 'customs'
              }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_period"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="deadline"
            type="datepicker"
            :label="$t('fields.deadline')"
            :view="view"
            v-model="formData.deadline"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDeadline"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_deadline"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriod"
            type="datepicker"
            :label="$t('fields.accountingPeriod')"
            :view="view"
            :disabled="true"
            v-model="formData.accounting_period"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="assignDate"
            type="datepicker"
            :label="$t('fields.assignDate')"
            :view="view"
            v-model="formData.assign_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAssignDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_assign_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="assignTo"
            type="select-server"
            :label="$t('fields.assignTo')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_header_id=${auditId}`
            }"
            v-model="formData.assign_to"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAssignTo"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_assign_to"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedDate"
            type="datepicker"
            :label="$t('fields.prepareDate')"
            :view="view"
            :disabled="true"
            v-model="formData.prepare_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedBy"
            type="select-server"
            :label="$t('fields.preparedBy')"
            :view="view"
            :disabled="true"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.prepare_by"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewDate"
            type="datepicker"
            :label="$t('fields.reviewDate')"
            :view="view"
            :disabled="true"
            v-model="formData.review_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewedBy"
            type="select-server"
            :label="$t('fields.reviewedBy')"
            :view="view"
            :disabled="true"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.review_by"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="taskType"
            type="select"
            rules="required"
            :label="$t('fields.taskType')"
            :view="view"
            v-model="formData.task_type"
            :items="[
              {
                label: $t('label.debitAndCreditSum'),
                value: 'DCS'
              },
              {
                label: $t('label.debitAndCreditDetail'),
                value: 'DCD'
              },
              {
                label: $t('label.subsidiaryAccount'),
                value: 'SA'
              },
              { label: $t('label.stockDetailOutstanding'), value: 'STO' },
              { label: $t('label.combinedInOut'), value: 'CIO' },
              { label: $t('label.stockValueOutstanding'), value: 'SVO' }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTaskType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_task_type"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodBeginning"
            type="datepicker"
            :label="$t('fields.accountingPeriodBeginning')"
            :view="view"
            :disabled="true"
            v-model="formData.accounting_period_beginning"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodBeginning"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_beginning"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodEnding"
            rules="required"
            type="datepicker"
            :label="$t('fields.accountingPeriodEnding')"
            :view="view"
            :disabled="true"
            v-model="formData.accounting_period_ending"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodEnding"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_ending"
          />
        </v-col>
      </v-row>

      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>{{ $t('menu.description') }}</v-tab>
          <v-tab v-if="formData?.audit_id != null">{{
            $t('menu.taskObjective')
          }}</v-tab>
          <v-tab>{{ $t('menu.conclusion') }}</v-tab>
          <v-tab>{{ $t('menu.subTask') }}</v-tab>
          <v-tab>{{ $t('menu.requiredDocument') }}</v-tab>
          <v-tab>{{ $t('menu.propertyAndAgreement') }}</v-tab>
          <v-tab>{{ $t('menu.riskProfile') }}</v-tab>
          <v-tab>{{ $t('menu.activityProfile') }}</v-tab>
          <v-tab>{{ $t('menu.questionnaire') }}</v-tab>
          <v-tab v-if="formData?.audit_id != null">{{
            $t('menu.adjustment')
          }}</v-tab>
          <v-tab v-if="formData?.audit_id != null">{{
            $t('menu.sumAdjustment')
          }}</v-tab>
          <v-tab>{{ $t('menu.timesheetByMember') }}</v-tab>
          <v-tab>{{ $t('menu.timesheetByPosition') }}</v-tab>
          <v-tab>{{ $t('menu.timesheet') }}</v-tab>
          <v-tab v-if="isShowDebitSum">{{ $t('menu.debitSum') }}</v-tab>
          <v-tab v-if="isShowDebitDetail">{{ $t('menu.debitDetail') }}</v-tab>
          <v-tab v-if="isShowSubsidiary">{{
            $t('menu.subsidiaryAccount')
          }}</v-tab>
          <v-tab v-if="isShowStockDetail">{{
            $t('menu.stockDetailOutstanding')
          }}</v-tab>
          <v-tab v-if="isShowCombinedInOut">{{
            $t('menu.combinedInOut')
          }}</v-tab>
          <v-tab v-if="isShowStockValue">{{
            $t('menu.stockValueOutstanding')
          }}</v-tab>
          <v-tab>{{ $t('menu.standardManDay') }}</v-tab>
          <v-tab v-if="formData?.audit_id != null">{{
            $t('menu.relatedCOA')
          }}</v-tab>
          <v-tab v-if="formData?.audit_id != null">{{
            $t('menu.sumRelatedCOA')
          }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Description Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-input
                  name="Description"
                  type="html-editor"
                  :label="$t('fields.description')"
                  :view="view"
                  v-model="formData.description"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Objective Tab -->
          <v-tab-item v-if="formData?.audit_id != null">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="objectiveHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :loading="loading"
                  :is-selecteable="false"
                  :server-items="objectiveItems"
                  :server-items-length="objectiveItemsCount"
                  @server="getObjectives"
                  @edit="onEditObjective"
                  @delete="onDeleteObjective"
                >
                  <template
                    v-slot:[`item.chart_of_account_details`]="{ value }"
                  >
                    <div>{{ displayChartOfAccountDetails(value) }}</div>
                  </template>
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Objective</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialogObjective" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  {{ $t('btn.newItem') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingObjective.objective"
                                        name="objective_objective"
                                        type="select-server"
                                        :label="$t('fields.objective')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'objective/objective/?state=approved&active=true'
                                        }"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="
                                          editingObjective.chart_of_account_details
                                        "
                                        name="objective_chart_of_account_details"
                                        type="chips-server"
                                        :label="$t('fields.chartOfAccountName')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`
                                        }"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseObjective"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveObjective"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Conclusion Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="conclusionHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :loading="loading"
                  :is-selecteable="false"
                  :server-items="conclusionItems"
                  :server-items-length="conclusionItemsCount"
                  @server="getConclusions"
                  @edit="onEditConclusion"
                  @delete="onDeleteConclusion"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Conclusion</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog
                            v-model="dialogConclusion"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  {{ $t('btn.newItem') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingConclusion.report_type"
                                        name="conclusion_report_type"
                                        type="select"
                                        :label="$t('fields.report')"
                                        :view="view"
                                        :items="conclusionReportTypes"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingConclusion.conclusion"
                                        name="conclusion_conclusion"
                                        type="html-editor"
                                        :label="$t('fields.conclusion')"
                                        :view="view"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseConclusion"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveConclusion"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.conclusion="{ item }">
                    <span style="white-space: pre">{{
                      stripHtml(item.conclusion)
                    }}</span>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Sub Task Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  ref="subtaskTable"
                  server-side
                  model="task"
                  app="audit"
                  :headers="subtaskHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :loading="loading"
                  :is-selecteable="false"
                  :server-items="subtasks"
                  :server-items-length="subtasksCount"
                  @server="getSubtasks"
                  @edit="onEditTask"
                  @view="onEditTask"
                  @delete="onDeleteTask"
                  :is-expand-server="true"
                  :single-expand="true"
                  :expand-server-headers="subtaskHeaders"
                  :expand-items="grandChildSubtasks"
                  :expand-items-length="grandChildSubtasksLength"
                  @item-expanded="getGrandChildSubtasks"
                  @expand-view="onEditTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Sub Task</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <slot name="actions">
                            <slot name="prefix-actions" />
                            <v-btn
                              color="primary"
                              dark
                              v-show="mode === 'edit'"
                              @click="onNewTaskClick()"
                            >
                              {{ $t('btn.newItem') }}
                            </v-btn>
                            <slot name="suffix-actions" />
                          </slot>
                        </v-toolbar>
                      </div>
                    </div>
                    <v-dialog v-model="dialogNewTask" max-width="300px">
                      <v-card>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                class="app-input--approve-wrapper"
                              >
                                <app-input
                                  v-model="newTaskTemplate"
                                  name="taskTemplate"
                                  type="select-server"
                                  :label="$t('fields.taskTemplate')"
                                  :view="view"
                                  :binds="{
                                    apiUrl:
                                      'task/task-template-header/?active=true'
                                  }"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="onCreateNewTaskClick"
                          >
                            {{ $t('btn.create') }}
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="onCancelNewTaskClick"
                          >
                            {{ $t('btn.cancel') }}
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="onSaveNewTaskClick"
                          >
                            {{ $t('btn.save') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Required Document Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="audit"
                    model="taskrequireddocument"
                    :headers="requiredDocumentHeader"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="requiredDocumentItems"
                    :clientItems="requiredDocumentItems"
                    :loading="loading"
                    :server-items-length="requiredDocumentItemsLength"
                    v-model="requiredDocumentSelected"
                    @server="getRequiredDocumentItems"
                    @client="getRequiredDocumentClientItems"
                    @delete="onDeleteItem"
                    @edit="onEdit"
                  >
                    <template v-slot:item.personal_document_id="{ item }">
                      <template v-if="item.personal_document_id">
                        <router-link
                          :to="{
                            name:
                              item.personal_document_id.type === 'company'
                                ? 'otherDocumentEdit'
                                : 'personalDocumentEdit',
                            params: { id: item.personal_document_id.id }
                          }"
                          target="_blank"
                        >
                          <v-icon color="primary">mdi-link</v-icon>
                        </router-link>
                      </template>
                    </template>
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >{{ $t('menu.requiredDocument') }}
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogRequiredDocument"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="
                                      requiredDocumentSelected.length &&
                                      mode === 'edit'
                                    "
                                    color="error"
                                    @click="onDelete(requiredDocumentSelected)"
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedItem.document_template_id
                                          "
                                          name="documentTemplate"
                                          rules="required"
                                          type="select-server"
                                          :label="$t('fields.requiredDocument')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'doc-template/document/?active=true'
                                          }"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedItem.personal_document_id
                                          "
                                          name="personal_document"
                                          type="select-server"
                                          :label="
                                            $t('label.selectRequiredDocument')
                                          "
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              editedItem.document_template_id &&
                                              formData.contact_id
                                                ? `contact/personal-doc/?document_template_id=${editedItem.document_template_id.id}&contact_ids=${formData.contact_id.id}&state=approved&active=true`
                                                : '',
                                            itemText: 'document_name'
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedItem.contact_business_unit_id
                                          "
                                          name="contact_business_unit"
                                          type="select-server"
                                          :label="$t('fields.businessUnit')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: formData.contact_id
                                              ? `contact/contact-business-unit/?contact_id=${formData.contact_id.id}`
                                              : '',
                                            itemText: 'business_unit_id.name'
                                          }"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.contact_branch_id"
                                          name="contact_branch"
                                          type="select-server"
                                          :label="$t('fields.branch')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: formData.contact_id
                                              ? `contact/contact-branch?contact_id=${formData.contact_id.id}`
                                              : ''
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeRequiredDocument"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveRequiredDocument"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Property and agreement Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="audit"
                    model="taskpropertyandagreement"
                    :headers="taskPropertyAndAgreementHeader"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="taskPropertyAndAgreementItems"
                    :clientItems="taskPropertyAndAgreementItems"
                    :loading="loading"
                    :server-items-length="taskPropertyAndAgreementItemsLength"
                    v-model="taskPropertyAndAgreementSelected"
                    @server="getTaskPropertyAndAgreementItems"
                    @client="getTaskPropertyAndAgreementClientItems"
                    @delete="onDeleteTaskPropertyAndAgreementItem"
                    @edit="onEditTaskPropertyAndAgreement"
                  >
                    <template v-slot:item.group_link="{ item }">
                      <template v-if="item.property_and_agreement">
                        <router-link
                          :to="{
                            name:
                              item.property_and_agreement === 'liability'
                                ? 'liabilityEdit'
                                : item.property_and_agreement === 'asset'
                                ? 'assetEdit'
                                : item.property_and_agreement === 'expense'
                                ? 'expenseEdit'
                                : item.property_and_agreement === 'revenue'
                                ? 'revenueEdit'
                                : item.property_and_agreement === 'human_asset'
                                ? 'humanAssetEdit'
                                : item.property_and_agreement === 'lawsuit'
                                ? 'lawsuitEdit'
                                : 'equityEdit',
                            params: { id: item.group_id }
                          }"
                          target="_blank"
                        >
                          <v-icon color="primary">mdi-link</v-icon>
                        </router-link>
                      </template>
                    </template>
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >{{ $t('menu.propertyAndAgreement') }}
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogTaskPropertyAndAgreement"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="
                                      taskPropertyAndAgreementSelected.length &&
                                      mode === 'edit'
                                    "
                                    color="error"
                                    @click="
                                      onDeleteTaskPropertyAndAgreement(
                                        taskPropertyAndAgreementSelected
                                      )
                                    "
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedPropertyAndAgreementItem.property_and_agreement
                                          "
                                          @input="getGroupName"
                                          name="porpery_and_agreement"
                                          type="select"
                                          :label="
                                            $t('fields.propertyAndAgreement')
                                          "
                                          :view="view"
                                          :items="[
                                            {
                                              label: $t('label.asset'),
                                              value: 'asset'
                                            },
                                            {
                                              label: $t('label.liability'),
                                              value: 'liability'
                                            },
                                            {
                                              label: $t('label.equity'),
                                              value: 'equity'
                                            },
                                            {
                                              label: $t('label.revenue'),
                                              value: 'revenue'
                                            },
                                            {
                                              label: $t('label.expense'),
                                              value: 'expense'
                                            },
                                            {
                                              label: $t('label.human_asset'),
                                              value: 'human_asset'
                                            },
                                            {
                                              label: $t('label.lawsuit'),
                                              value: 'lawsuit'
                                            }
                                          ]"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="group_name"
                                          type="select-server"
                                          :label="$t('fields.groupName')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: groupNameUrl
                                          }"
                                          v-model="
                                            editedPropertyAndAgreementItem.group_name
                                          "
                                          :disabled="
                                            !Boolean(
                                              editedPropertyAndAgreementItem.property_and_agreement
                                            )
                                          "
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="contact_branch_id"
                                          type="select-server"
                                          :label="$t('fields.branchName')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: `contact/contact-branch/?contact_id=${formData.contact_id.id}`
                                          }"
                                          v-model="
                                            editedPropertyAndAgreementItem.contact_branch_id
                                          "
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="business_unit_id"
                                          type="select-server"
                                          :label="$t('fields.businessUnit')"
                                          :view="view"
                                          :binds="{
                                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                                          }"
                                          v-model="
                                            editedPropertyAndAgreementItem.business_unit_id
                                          "
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="note"
                                          type="text"
                                          :label="$t('fields.note')"
                                          :view="view"
                                          v-model="
                                            editedPropertyAndAgreementItem.note
                                          "
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeTaskPropertyAndAgreement"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveTaskPropertyAndAgreement"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Risk Profile Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="audit"
                    model="taskriskprofile"
                    :headers="taskRiskProfileHeader"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="taskRiskProfileItems"
                    :clientItems="taskRiskProfileItems"
                    :loading="loading"
                    :server-items-length="taskRiskProfileItemsLength"
                    v-model="taskRiskProfileSelected"
                    @server="getTaskRiskProfileItems"
                    @client="getTaskRiskProfileClientItems"
                    @delete="onDeleteTaskRiskProfileItem"
                    @edit="onEditTaskRiskProfile"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >{{ $t('menu.riskProfile') }}
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogTaskRiskProfile"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="
                                      taskRiskProfileSelected.length &&
                                      mode === 'edit'
                                    "
                                    color="error"
                                    @click="
                                      onDeleteTaskRiskProfile(
                                        taskRiskProfileSelected
                                      )
                                    "
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.risk_profile_id"
                                          name="documentTemplate"
                                          rules="required"
                                          type="select-server"
                                          :label="$t('fields.riskProfile')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'risk/risk-profile-template-header/?active=true'
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeTaskRiskProfile"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveTaskRiskProfile"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Activity Profile Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="audit"
                    model="taskactivityprofile"
                    :headers="taskActivityProfileHeader"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="taskActivityProfileItems"
                    :clientItems="taskActivityProfileItems"
                    :loading="loading"
                    :server-items-length="taskActivityProfileItemsLength"
                    v-model="taskActivityProfileSelected"
                    @server="getTaskActivityProfileItems"
                    @client="getTaskActivityProfileClientItems"
                    @delete="onDeleteTaskActivityProfileItem"
                    @edit="onEditTaskActivityProfile"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >{{ $t('menu.activityProfile') }}
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogTaskActivityProfile"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="
                                      taskActivityProfileSelected.length &&
                                      mode === 'edit'
                                    "
                                    color="error"
                                    @click="
                                      onDeleteTaskActivityProfile(
                                        taskActivityProfileSelected
                                      )
                                    "
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="
                                            editedItem.activity_profile_id
                                          "
                                          name="documentTemplate"
                                          rules="required"
                                          type="select-server"
                                          :label="$t('fields.activityProfile')"
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'activity/activity-profile-header/?active=true'
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeTaskActivityProfile"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveTaskActivityProfile"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- questionnaire Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="audit"
                    model="taskquestionnaire"
                    :headers="taskQuestionnaireHeader"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :serverItems="taskQuestionnaireItems"
                    :clientItems="taskQuestionnaireItems"
                    :loading="loading"
                    :server-items-length="taskQuestionnaireItemsLength"
                    v-model="taskQuestionnaireSelected"
                    @server="getTaskQuestionnaireItems"
                    @client="getTaskQuestionnaireClientItems"
                    @delete="onDeleteTaskQuestionnaireItem"
                    @edit="onEditTaskQuestionnaire"
                    @view="onViewQuestionAnswer"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view--header">
                          <v-toolbar flat>
                            <v-toolbar-title
                              >{{ $t('menu.questionnaire') }}
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog
                              v-model="dialogQuestionAnswer"
                              max-width="1500px"
                            >
                              <v-card>
                                <v-card-text class="pt-4">
                                  <v-toolbar-title>Answer</v-toolbar-title>
                                  <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                  ></v-divider>
                                  <v-spacer></v-spacer>
                                  <!-- <p v-show="false">{{ totalScore }}</p> -->
                                  <v-row
                                    v-if="taskQuestionnaireType === 'Text'"
                                  >
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.text_answer"
                                        name="answer"
                                        :label="$t('fields.answer')"
                                        :view="false"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      taskQuestionnaireType === 'MultipleChoice'
                                    "
                                  >
                                    <v-col cols="12" sm="6" md="4">
                                      <v-radio-group
                                        v-model="editedItem.questionAnswer"
                                      >
                                        <v-radio
                                          v-for="item in answerItem"
                                          :key="item.id"
                                          :label="item.choice"
                                          :value="{
                                            id: item.id,
                                            points: item.points
                                          }"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="taskQuestionnaireType === 'Checkbox'"
                                  >
                                    <v-col cols="12" sm="6" md="4">
                                      <p>Multiple Select</p>
                                      <div
                                        v-for="item in answerItem"
                                        :key="item.id"
                                      >
                                        <v-checkbox
                                          v-model="selectedItem"
                                          :label="item.choice"
                                          :value="{
                                            id: item.id,
                                            points: item.points
                                          }"
                                          :key="item.id"
                                        ></v-checkbox>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    ><v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedItem.answerScore"
                                        name="answerScore"
                                        :label="$t('fields.score')"
                                        type="number"
                                        :view="false"
                                      ></app-input> </v-col
                                  ></v-row>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeQuestionAnswer"
                                  >
                                    Close
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveQuestionAnswer"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                            <v-dialog
                              v-model="dialogTaskQuestionnaire"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                    @click="onQuestionnaireNewItemClick"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="
                                      taskQuestionnaireSelected.length &&
                                      mode === 'edit'
                                    "
                                    color="error"
                                    @click="
                                      onDeleteTaskQuestionnaire(
                                        taskQuestionnaireSelected
                                      )
                                    "
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row v-if="isNewQuestionnaireClicked">
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="Select Quesiton"
                                          type="select"
                                          class="w-100"
                                          :label="$t('fields.selectQuestion')"
                                          :items="[
                                            {
                                              label: 'Pre Config Questionnaire',
                                              value: 'PreConfig'
                                            },
                                            {
                                              label: 'Add Manual Question',
                                              value: 'Manual'
                                            }
                                          ]"
                                          v-model="editedItem.selectQuestion"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      v-if="
                                        editedItem.selectQuestion ===
                                        'PreConfig'
                                      "
                                    >
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="Questionnaire"
                                          type="select-server"
                                          class="w-200"
                                          :label="$t('fields.questionnaire')"
                                          v-model="
                                            editedItem.questionnaire_header_id
                                          "
                                          :view="view"
                                          :binds="{
                                            apiUrl:
                                              'questionnaire/questionnaire-header?active=true'
                                          }"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      v-if="
                                        editedItem.selectQuestion ===
                                          'Manual' || !isNewQuestionnaireClicked
                                      "
                                    >
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.sequence"
                                          name="sequence"
                                          :label="$t('fields.sequence')"
                                          type="number"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.question"
                                          name="question"
                                          :label="$t('fields.question')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          name="Question Type"
                                          type="select"
                                          class="w-100"
                                          :label="$t('fields.questionType')"
                                          :items="[
                                            {
                                              label: 'Text',
                                              value: 'Text'
                                            },
                                            {
                                              label: 'MultipleChoice',
                                              value: 'MultipleChoice'
                                            },
                                            {
                                              label: 'Checkbox',
                                              value: 'Checkbox'
                                            }
                                          ]"
                                          v-model="editedItem.question_type"
                                          :view="view"
                                        >
                                        </app-input>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="4" style="text-align: right">
                                        <v-btn
                                          color="primary"
                                          dark
                                          v-show="mode === 'edit'"
                                          @click="onEditQuestionnaireLine()"
                                          v-if="
                                            (editedItem.selectQuestion ===
                                              'Manual' ||
                                              !isNewQuestionnaireClicked) &&
                                            (editedItem.question_type ===
                                              'MultipleChoice' ||
                                              editedItem.question_type ===
                                                'Checkbox')
                                          "
                                        >
                                          {{ $t('btn.newItem') }}
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col col="12">
                                        <app-table
                                          app="questionnaire"
                                          model="questionnaireline"
                                          :headers="questionnaireLineHeaders"
                                          :hide-edit="mode !== 'edit'"
                                          :hide-delete="mode !== 'edit'"
                                          :is-selecteable="false"
                                          :client-items="
                                            editingQuestionnaire.lines
                                          "
                                          @edit="onEditQuestionnaireLine"
                                          @delete="onDeleteQuestionnaireLine"
                                          v-if="
                                            (editedItem.selectQuestion ===
                                              'Manual' ||
                                              !isNewQuestionnaireClicked) &&
                                            (editedItem.question_type ===
                                              'MultipleChoice' ||
                                              editedItem.question_type ===
                                                'Checkbox')
                                          "
                                        >
                                        </app-table>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      v-if="
                                        editedItem.selectQuestion ===
                                          'Manual' || !isNewQuestionnaireClicked
                                      "
                                    >
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.total_points"
                                          name="total_points"
                                          :label="$t('fields.totalPoints')"
                                          type="number"
                                          :view="view"
                                          :disabled="
                                            editedItem.question_type ===
                                              'MultipleChoice' ||
                                            editedItem.question_type ===
                                              'Checkbox'
                                          "
                                        ></app-input>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <app-input
                                          v-model="editedItem.note"
                                          name="note"
                                          :label="$t('fields.note')"
                                          :view="view"
                                        ></app-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeQuestionnaireDetail"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveQuestionnaireDetail"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogQuestionnaireLine"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        class="app-input--approve-wrapper"
                                      >
                                        <app-input
                                          v-model="
                                            editingQuestionnaireLine.choice
                                          "
                                          name="choice"
                                          :label="$t('fields.choice')"
                                          :view="view"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        class="app-input--approve-wrapper"
                                      >
                                        <app-input
                                          v-model="
                                            editingQuestionnaireLine.points
                                          "
                                          name="points"
                                          type="number"
                                          :label="$t('fields.points')"
                                          :view="view"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="onCloseQuestionnaireLine"
                                  >
                                    {{ $t('btn.cancel') }}
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="onSaveQuestionnaireLine"
                                  >
                                    {{ $t('btn.save') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-subtitle-1">Totals</td>
                        <td class="text-subtitle-1">
                          {{ sumPoints }}
                        </td>
                        <td class="text-subtitle-1">
                          {{ sumScore }}
                        </td>
                        <td></td>
                      </tr>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- adjustment Tab -->
          <v-tab-item v-if="formData?.audit_id != null">
            <v-toolbar flat>
              <v-toolbar-title>Adjustment</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditAdjustment()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-dialog v-model="dialogAdjustment">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="3" class="app-input--approve-wrapper">
                        <app-input
                          name="adjustment_ref"
                          type="text"
                          :label="$t('fields.ref')"
                          v-model="editingAdjustment.ref"
                        />
                      </v-col>
                      <v-col cols="3" class="app-input--approve-wrapper">
                        <app-input
                          name="adjustment_adj_type"
                          type="select"
                          :label="$t('fields.adjType')"
                          v-model="editingAdjustment.adj_type"
                          :items="adjustmentTypeItems"
                        />
                      </v-col>
                      <v-col cols="4" class="app-input--approve-wrapper">
                        <app-input
                          name="adjustment_adj_note"
                          type="text"
                          :label="$t('fields.adjNote')"
                          v-model="editingAdjustment.adj_note"
                        />
                      </v-col>
                      <v-col cols="2" style="text-align: right">
                        <v-btn
                          color="primary"
                          dark
                          v-show="mode === 'edit'"
                          @click="onEditAdjustmentLine()"
                        >
                          {{ $t('btn.newItem') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          model="task"
                          app="audit"
                          :headers="adjustmentLineHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="false"
                          :client-items="editingAdjustment.lines"
                          @edit="onEditAdjustmentLine"
                          @delete="onDeleteAdjustmentLine"
                        >
                          <template v-slot:foot>
                            <tfoot>
                              <tr>
                                <td></td>
                                <td style="text-align: right">
                                  <strong>Total</strong>
                                </td>
                                <td>
                                  {{
                                    accountingFormat(
                                      editingAdjustmentTotalDebit
                                    )
                                  }}
                                </td>
                                <td>
                                  {{
                                    accountingFormat(
                                      editingAdjustmentTotalCredit
                                    )
                                  }}
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </template>
                        </app-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onCloseAdjustment">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveAdjustment">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAdjustmentLine" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="app-input--approve-wrapper">
                        <app-input
                          v-model="
                            editingAdjustmentLine.chart_of_account_detail
                          "
                          name="adjustment_line_chart_of_account_detail"
                          type="select-server"
                          :label="$t('fields.chartOfAccount')"
                          :view="view"
                          :binds="{
                            apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`,
                            itemText: (item) => `${item.code} ${item.name}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="app-input--approve-wrapper">
                        <app-input
                          name="adjustment_line_debit"
                          type="number"
                          :label="$t('fields.adjDebit')"
                          v-model="editingAdjustmentLine.debit"
                        />
                      </v-col>
                      <v-col cols="6" class="app-input--approve-wrapper">
                        <app-input
                          name="adjustment_line_creebit"
                          type="number"
                          :label="$t('fields.adjCredit')"
                          v-model="editingAdjustmentLine.credit"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="app-input--approve-wrapper">
                        <app-input
                          name="adjustment_line_note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editingAdjustmentLine.note"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseAdjustmentLine"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSaveAdjustmentLine"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-container fluid>
              <v-row v-for="(adjustment, index) in adjustments" :key="index">
                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="2" xs="12">
                          <app-input
                            name="reference"
                            type="text"
                            :label="$t('fields.ref')"
                            v-model="adjustments[index].ref"
                            view
                          />
                        </v-col>
                        <v-col cols="2" xs="12">
                          <app-input
                            name="reference"
                            type="select"
                            :label="$t('fields.adjType')"
                            v-model="adjustments[index].adj_type"
                            :items="adjustmentTypeItems"
                            view
                          />
                        </v-col>
                        <v-col cols="2" xs="12">
                          <app-input
                            name="reference"
                            type="text"
                            :label="$t('fields.adjNote')"
                            v-model="adjustments[index].adj_note"
                            view
                          />
                        </v-col>

                        <v-col cols="2" style="text-align: right">
                          <v-btn
                            color="primary"
                            dark
                            v-show="
                              mode === 'edit' &&
                              adjustment.is_before_audit === true &&
                              adjustment.is_after_audit === true
                            "
                            @click="onCreateCustomerAudit(adjustment)"
                          >
                            {{ $t('btn.createCustomerAudit') }}
                          </v-btn>
                        </v-col>
                        <v-col cols="2" style="text-align: right">
                          <v-icon
                            v-show="mode === 'edit'"
                            @click="onEditAdjustment(adjustment)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            v-show="
                              mode === 'edit' &&
                              adjustment.is_after_audit === true
                            "
                            class="ml-2"
                            @click="onDeleteAdjustment([adjustment])"
                          >
                            mdi-delete
                          </v-icon>
                        </v-col>
                        <v-col cols="2" style="text-align: right">
                          <v-span
                            v-show="
                              adjustment.is_before_audit === false &&
                              adjustment.is_after_audit === true
                            "
                            style="font-size: 20px; color: red"
                          >
                            Customer Audit</v-span
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <app-table
                            model="task"
                            app="audit"
                            :headers="adjustmentHeaders"
                            :hide-edit="mode !== 'edit'"
                            :hide-delete="mode !== 'edit'"
                            :loading="loading"
                            :is-selecteable="false"
                            :client-items="adjustments[index].lines"
                          >
                            <template v-slot:foot>
                              <tfoot>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td style="text-align: right">
                                    <strong>Total</strong>
                                  </td>
                                  <td>
                                    {{
                                      accountingFormat(
                                        calcAdjustmentTotalDebit(
                                          adjustments[index]
                                        )
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      accountingFormat(
                                        calcAdjustmentTotalCredit(
                                          adjustments[index]
                                        )
                                      )
                                    }}
                                  </td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </template>
                          </app-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <!-- sum.adjustment Tab -->
          <v-tab-item v-if="formData?.audit_id != null">
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('menu.sumAdjustment') }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
            <v-card>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="adjustmentLinesFromSubtasksHeaders"
                  hide-edit
                  hide-delete
                  hide-default-footer
                  :is-selecteable="false"
                  :server-items="adjustmentLinesFromSubtasks"
                  :server-items-length="adjustmentLinesFromSubtasks.length"
                  @server="getAdjustmentLinesFromSubtasks"
                >
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td>{{ adjustmentLinesFromSubtasksTotalDebit }}</td>
                        <td>{{ adjustmentLinesFromSubtasksTotalCredit }}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- timesheet by member Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="timesheetByMemberHeaders"
                  :serverItems="timesheetByMembers"
                  :loading="loading"
                  :server-items-length="timesheetByMembersCount"
                  hide-delete
                  hide-edit
                  :is-selecteable="false"
                  @server="getTimesheetByMembers"
                  :is-expand-server="true"
                  :single-expand="true"
                  :expand-server-headers="timesheetByMemberIdHeaders"
                  :expand-items="timesheetByMemberIdItems"
                  :expand-items-length="timesheetByMemberIdItemsCount"
                  @item-expanded="getTimesheetByMemberIdItems"
                  sub-item-key="member_id"
                  item-key="member_id"
                >
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByMemberSumAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{ percentFormat(timesheetByMemberSumWeight) }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByMemberSumFinishedAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(timesheetByMemberSumActualManDay)
                          }}
                        </td>
                        <td>
                          {{ hoursMinutesFormat(timesheetByMemberSumVariance) }}
                        </td>
                        <td>
                          {{
                            percentFormat(timesheetByMemberSumTaskPerformance)
                          }}
                        </td>
                        <td>
                          {{ percentFormat(timesheetByMemberSumWorkProgress) }}
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- timesheet by position Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="timesheetByPositionHeaders"
                  :serverItems="timesheetByPositions"
                  :loading="loading"
                  :server-items-length="timesheetByPositionsCount"
                  hide-delete
                  hide-edit
                  :is-selecteable="false"
                  @server="getTimesheetByPositions"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >Timesheet By Position</v-toolbar-title
                          >
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>

                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumStandardManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(timesheetByPositionSumUnassigned)
                          }}
                        </td>
                        <td>
                          {{
                            percentFormat(
                              timesheetByPositionSumAssignedProgress
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumFinishedAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumActualManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(timesheetByPositionSumVariance)
                          }}
                        </td>
                        <td>
                          {{
                            percentFormat(timesheetByPositionSumTaskPerformance)
                          }}
                        </td>
                        <td>
                          {{
                            percentFormat(timesheetByPositionSumWorkProgress)
                          }}
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- timesheet Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="timesheetItemHeader"
                  :serverItems="timesheetItemItems"
                  :clientItems="timesheetItemItems"
                  :loading="loading"
                  :server-items-length="timesheetItemServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  :hide-edit="mode !== 'edit'"
                  v-model="selected"
                  @server="getTimesheetItemItems"
                  @delete="onDeleteTimesheetItemItem"
                  @edit="onEditTimesheetItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Timesheet</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogTimesheetItem"
                            max-width="900px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteTimesheetItemItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>
                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        ref="timesheet_audit_plan_member"
                                        name="name"
                                        type="select"
                                        :label="$t('fields.name')"
                                        :view="view"
                                        v-model="
                                          editedTimesheetItemItem.audit_plan_member
                                        "
                                        :items="memberTimesheetItems"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        ref="timesheet_assigned_by"
                                        name="assigned_by"
                                        type="select"
                                        :label="$t('fields.assignedBy')"
                                        :view="view"
                                        v-model="
                                          editedTimesheetItemItem.assigned_by
                                        "
                                        :items="memberTimesheetItems"
                                      ></app-input
                                    ></v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        ref="timesheet_task_standard_man_day"
                                        name="task_standard_man_day"
                                        type="select-server"
                                        :label="$t('fields.taskPosition')"
                                        :view="view"
                                        v-model="
                                          editedTimesheetItemItem.task_standard_man_day
                                        "
                                        :binds="{
                                          apiUrl: `task/task/${$route.params.id}/standard-man-day/?active=true`,
                                          itemText: 'position.name'
                                        }"
                                      ></app-input
                                    ></v-col>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        name="expectedManDay"
                                        :label="$t('fields.assignedManDay')"
                                        :view="view"
                                        :handleBlur="
                                          () =>
                                            onTimeHrsBlur(
                                              'editedTimesheetItemItem',
                                              'expected_man_day',
                                              editedTimesheetItemItem.expected_man_day
                                            )
                                        "
                                        :handleKeyDown="onTimeHrsKeyDow"
                                        v-model="
                                          editedTimesheetItemItem.expected_man_day
                                        "
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <app-date-time-picker
                                        name="date"
                                        :label="$t('fields.assignedDate')"
                                        v-model="editedTimesheetItemItem.date"
                                      />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        name="actualManDay"
                                        :label="$t('fields.actualManDay')"
                                        :view="view"
                                        :handleBlur="
                                          () =>
                                            onTimeHrsBlur(
                                              'editedTimesheetItemItem',
                                              'actual_man_day',
                                              editedTimesheetItemItem.actual_man_day
                                            )
                                        "
                                        :handleKeyDown="onTimeHrsKeyDow"
                                        v-model="
                                          editedTimesheetItemItem.actual_man_day
                                        "
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <app-date-time-picker
                                        name="actual_start_date"
                                        :label="$t('fields.actualStartDate')"
                                        v-model="
                                          editedTimesheetItemItem.actual_start_date
                                        "
                                      />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        name="note"
                                        :label="$t('fields.note')"
                                        :view="view"
                                        v-model="editedTimesheetItemItem.note"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeTimesheetItem"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveTimesheetItem"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>

                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ hoursMinutesFormat(timesheetSumAssignedManDay) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ hoursMinutesFormat(timesheetSumActualManDay) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- debit sum Tab -->
          <v-tab-item v-if="isShowDebitSum">
            <v-toolbar flat>
              <v-toolbar-title>Debit Sum</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onDebitSumBiasClick"
                >Bias
              </v-btn>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onDebitSumNonBiasClick"
                >Non-bias
              </v-btn>
              <v-btn
                color="error"
                dark
                v-show="mode === 'edit'"
                @click="onResetSumBiasClick"
                >Reset
              </v-btn>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditDebitSummary()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onImportDebitSummary()"
                >
                  {{ $t('btn.import') }}
                </v-btn>
                <v-btn
                  v-show="debitSummarySelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteDebitSummary(debitSummarySelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-dialog v-model="dialogDebitSum" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_code"
                          type="text"
                          :label="$t('fields.partnerCode')"
                          v-model="editingDebitSum.partner_code"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_name"
                          type="text"
                          :label="$t('fields.partnerName')"
                          v-model="editingDebitSum.partner_name"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_branch"
                          type="text"
                          :label="$t('fields.partnerBranch')"
                          v-model="editingDebitSum.partner_branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_address"
                          type="text"
                          :label="$t('fields.partnerAddress')"
                          v-model="editingDebitSum.partner_address"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_attn_person"
                          type="text"
                          :label="$t('fields.partnerAttnPerson')"
                          v-model="editingDebitSum.partner_attn_person"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="balance"
                          type="number"
                          :label="$t('fields.balance')"
                          v-model="editingDebitSum.balance"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editingDebitSum.note"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="branch"
                          type="text"
                          :label="$t('fields.branch')"
                          v-model="editingDebitSum.branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingDebitSum.business_unit"
                          name="business_unit"
                          type="select-server"
                          :label="$t('fields.businessUnit')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">
                        <app-input
                          name="actual"
                          type="number"
                          :label="$t('fields.actual')"
                          v-model="editingDebitSum.actual"
                          :view="view"
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          :disabled="
                            !editingDebitSum.id || !editingDebitSum.subtask
                          "
                          color="primary"
                          dark
                          @click="onSumDetailFromDetailClick"
                          >Actual detail</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingDebitSum.status"
                          name="status"
                          type="select-server"
                          :label="$t('fields.status')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-status/'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingDebitSum.dummy"
                          name="dummy"
                          type="switch"
                          :label="$t('fields.dummy')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseDebitSummary"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveDebitSummary">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDebitSumImport" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-xlsx-import @change="onDebitSumImportFileChange" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          color="blue darken-1"
                          text
                          :href="debitSumTemplateUrl"
                        >
                          Download Template
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ tempDebitSumImportStatus }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseImportDebitSummary"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onImportDebitSummaryDo"
                  >
                    {{ $t('btn.import') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDebitSumBias" max-width="700px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditDebitSumBias()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="debitSummaryBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="true"
                          :server-items="debitSummaryBiases"
                          :server-items-length="debitSummaryBiasesCount"
                          @server="getDebitSumBiases"
                          @edit="onEditDebitSumBias"
                          @delete="onDeleteDebitSumBias"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitSumBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onDebitSumBiasSave">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditDebitSumBias" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="field"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.field')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-summary-bias-field/'
                          }"
                          v-model="editingDebitSumBias.field"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="operator"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.operator')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-bias-operator/'
                          }"
                          v-model="editingDebitSumBias.operator"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <app-input
                          name="value1"
                          type="text"
                          :label="$t('fields.value1')"
                          :view="view"
                          v-model="editingDebitSumBias.value1"
                        />
                      </v-col>
                      <v-col cols="5">
                        <app-input
                          name="value2"
                          type="text"
                          :label="$t('fields.value2')"
                          :disabled="
                            editingDebitSumBias.operator !== 'Between' &&
                            editingDebitSumBias.operator !== 'Not Between'
                          "
                          :rules="{
                            required: (value) => {
                              if (
                                editingDebitSumBias.operator !== 'Between' &&
                                editingDebitSumBias.operator !== 'Not Between'
                              )
                                return true
                              if (value) return true

                              return 'You must enter a value2.'
                            }
                          }"
                          v-model="editingDebitSumBias.value2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="reason"
                          type="text"
                          :label="$t('fields.reason')"
                          :view="view"
                          v-model="editingDebitSumBias.reason"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitSumBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitSumBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDebitSumNonBias" max-width="600px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditDebitSumNonBias"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="debitSummaryNonBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="true"
                          :server-items="debitSummaryNonBiases"
                          :server-items-length="debitSummaryNonBiasesCount"
                          @server="getDebitSumNonBiases"
                          @edit="onEditDebitSumNonBias"
                          @delete="onDeleteDebitSumNonBias"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitSumNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitSumNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditDebitSumNonBias" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_size"
                          rules="required"
                          type="number"
                          :label="$t('fields.samplingSize')"
                          :view="view"
                          v-model="editingDebitSumNonBias.sampling_size"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_method"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.samplingMethod')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-non-bias-sampling-method/'
                          }"
                          v-model="editingDebitSumNonBias.sampling_method"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitSumNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitSumNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="debitSummaryHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  v-model="debitSummarySelected"
                  :server-items="debitSummaries"
                  :server-items-length="debitSummariesCount"
                  @server="getDebitSummaries"
                  @edit="onEditDebitSummary"
                  @delete="onDeleteDebitSummary"
                >
                  <template v-slot:item.subtask="{ item }">
                    <template v-if="item.subtask">
                      <router-link
                        :to="{
                          name: 'taskEdit',
                          params: { id: item.subtask.id },
                          query: {
                            task_debit_summary_id: item.id,
                            task_type: 'DCD',
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from
                          }
                        }"
                        target="_blank"
                      >
                        <v-icon color="primary">mdi-link</v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'taskCreate',
                          query: {
                            task_debit_summary_id: item.id,
                            task_type: 'DCD',
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from,
                            parent_task_id: $route.params.id
                          }
                        }"
                      >
                        <v-icon color="green">mdi-link</v-icon>
                      </router-link>
                    </template>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td>
                          {{ accountingFormat(debitSummariesSumBalance) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ accountingFormat(debitSummariesSumActual) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- debit detail Tab -->
          <v-tab-item v-if="isShowDebitDetail">
            <v-toolbar flat>
              <v-toolbar-title>Debit Detail</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onDebitDetailBiasClick"
                >Bias
              </v-btn>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onDebitDetailNonBiasClick"
                >Non-bias
              </v-btn>
              <v-btn
                color="error"
                dark
                v-show="mode === 'edit'"
                @click="onResetDetailBiasClick"
                >Reset
              </v-btn>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditDebitDetail()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onImportDebitDetail()"
                >
                  {{ $t('btn.import') }}
                </v-btn>
                <v-btn
                  v-show="debitDetailSelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteDebitDetail(debitDetailSelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-dialog v-model="dialogDebitDetail" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_code"
                          type="text"
                          :label="$t('fields.partnerCode')"
                          v-model="editingDebitDetail.partner_code"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_name"
                          type="text"
                          :label="$t('fields.partnerName')"
                          v-model="editingDebitDetail.partner_name"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_branch"
                          type="text"
                          :label="$t('fields.partnerBranch')"
                          v-model="editingDebitDetail.partner_branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="doc_no"
                          type="text"
                          :label="$t('fields.docNo')"
                          v-model="editingDebitDetail.doc_no"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="doc_date"
                          type="datepicker"
                          :label="$t('fields.docDate')"
                          v-model="editingDebitDetail.doc_date"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="doc_amount"
                          type="number"
                          :label="$t('fields.docAmount')"
                          v-model="editingDebitDetail.doc_amount"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="paid_amount"
                          type="number"
                          :label="$t('fields.paidAmount')"
                          v-model="editingDebitDetail.paid_amount"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="remaining"
                          type="number"
                          :label="$t('fields.remaining')"
                          v-model="editingDebitDetail.remaining"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editingDebitDetail.note"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="branch"
                          type="text"
                          :label="$t('fields.branch')"
                          v-model="editingDebitDetail.branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingDebitDetail.business_unit"
                          name="business_unit"
                          type="select-server"
                          :label="$t('fields.businessUnit')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual"
                          type="number"
                          :label="$t('fields.actual')"
                          v-model="editingDebitDetail.actual"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingDebitDetail.status"
                          name="status"
                          type="select-server"
                          :label="$t('fields.status')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-status/'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingDebitDetail.dummy"
                          name="dummy"
                          type="switch"
                          :label="$t('fields.dummy')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onCloseDebitDetail">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveDebitDetail">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDebitDetailImport" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-xlsx-import
                          @change="onDebitDetailImportFileChange"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          color="blue darken-1"
                          text
                          :href="debitDetailTemplateUrl"
                        >
                          Download Template
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ tempDebitDetailImportStatus }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseImportDebitDetail"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onImportDebitDetailDo"
                  >
                    {{ $t('btn.import') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDebitDetailBias" max-width="700px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditDebitDetailBias()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="debitDetailBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="true"
                          :server-items="debitDetailBiases"
                          :server-items-length="debitDetailBiasesCount"
                          @server="getDebitDetailBiases"
                          @edit="onEditDebitDetailBias"
                          @delete="onDeleteDebitDetailBias"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitDetailBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitDetailBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditDebitDetailBias" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="field"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.field')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-detail-bias-field/'
                          }"
                          v-model="editingDebitDetailBias.field"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="operator"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.operator')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-bias-operator/'
                          }"
                          v-model="editingDebitDetailBias.operator"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <app-input
                          name="value1"
                          type="text"
                          :label="$t('fields.value1')"
                          :view="view"
                          v-model="editingDebitDetailBias.value1"
                        />
                      </v-col>
                      <v-col cols="5">
                        <app-input
                          name="value2"
                          type="text"
                          :label="$t('fields.value2')"
                          :disabled="
                            editingDebitDetailBias.operator !== 'Between' &&
                            editingDebitDetailBias.operator !== 'Not Between'
                          "
                          :rules="{
                            required: (value) => {
                              if (
                                editingDebitDetailBias.operator !== 'Between' &&
                                editingDebitDetailBias.operator !==
                                  'Not Between'
                              )
                                return true
                              if (value) return true

                              return 'You must enter a value2.'
                            }
                          }"
                          v-model="editingDebitDetailBias.value2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="reason"
                          type="text"
                          :label="$t('fields.reason')"
                          :view="view"
                          v-model="editingDebitDetailBias.reason"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitDetailBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitDetailBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDebitDetailNonBias" max-width="600px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditDebitDetailNonBias"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="debitDetailNonBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="true"
                          :server-items="debitDetailNonBiases"
                          :server-items-length="debitDetailNonBiasesCount"
                          @server="getDebitDetailNonBiases"
                          @edit="onEditDebitDetailNonBias"
                          @delete="onDeleteDebitDetailNonBias"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitDetailNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onDebitDetailNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditDebitDetailNonBias" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_size"
                          rules="required"
                          type="number"
                          :label="$t('fields.samplingSize')"
                          :view="view"
                          v-model="editingDebitDetailNonBias.sampling_size"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_method"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.samplingMethod')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-non-bias-sampling-method/'
                          }"
                          v-model="editingDebitDetailNonBias.sampling_method"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitDetailNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditDebitDetailNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="debitDetailHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  v-model="debitDetailSelected"
                  :server-items="debitDetails"
                  :server-items-length="debitDetailsCount"
                  @server="getDebitDetails"
                  @edit="onEditDebitDetail"
                  @delete="onDeleteDebitDetail"
                >
                  <template v-slot:item.subtask="{ item }">
                    <template v-if="item.subtask">
                      <router-link
                        :to="{
                          name: 'taskEdit',
                          params: { id: item.subtask.id },
                          query: {
                            task_debit_detail_id: item.id,
                            task_type: 'DCD',
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from
                          }
                        }"
                        target="_blank"
                      >
                        <v-icon color="primary">mdi-link</v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'taskCreate',
                          query: {
                            task_debit_detail_id: item.id,
                            task_type: 'DCD',
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from,
                            parent_task_id: $route.params.id
                          }
                        }"
                      >
                        <v-icon color="green">mdi-link</v-icon>
                      </router-link>
                    </template>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td>
                          {{ accountingFormat(debitDetailsSumDocAmount) }}
                        </td>
                        <td>
                          {{ accountingFormat(debitDetailsSumPaidAmount) }}
                        </td>
                        <td>
                          {{ accountingFormat(debitDetailsSumRemaining) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ accountingFormat(debitDetailsSumActual) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Subsidiary Account Tab -->
          <v-tab-item v-if="isShowSubsidiary">
            <v-toolbar flat>
              <v-toolbar-title>Subsidiary Account</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onSubsidiaryBiasClick"
                >Bias
              </v-btn>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onSubsidiaryNonBiasClick"
                >Non-bias
              </v-btn>
              <v-btn
                color="error"
                dark
                v-show="mode === 'edit'"
                @click="onResetSubsidiaryBiasClick"
                >Reset
              </v-btn>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditSubsidiary()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onImportSubsidiary()"
                >
                  {{ $t('btn.import') }}
                </v-btn>
                <v-btn
                  v-show="subsidiarySelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteSubsidiary(subsidiarySelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="subsidiaryHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  v-model="subsidiarySelected"
                  :server-items="subsidiaryItemItems"
                  :server-items-length="subsidiaryItemServerItemsLength"
                  @server="getSubsidiary"
                  @edit="onEditSubsidiary"
                  @delete="onDeleteSubsidiary"
                >
                  <template v-slot:[`item.subtask`]="{ item }">
                    <template v-if="item.subtask">
                      <router-link
                        :to="{
                          name: 'taskEdit',
                          params: { id: item.subtask.id },
                          query: {
                            task_subsidiary_id: item.id,
                            task_type: formData.task_type,
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from
                          }
                        }"
                        target="_blank"
                      >
                        <v-icon color="primary">mdi-link</v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'taskCreate',
                          query: {
                            task_subsidiary_id: item.id,
                            task_type: formData.task_type,
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from,
                            parent_task_id: $route.params.id
                          }
                        }"
                      >
                        <v-icon color="green">mdi-link</v-icon>
                      </router-link>
                    </template>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ accountingFormat(subsidiarySumBeginningBalance) }}
                        </td>
                        <td>
                          {{ accountingFormat(subsidiarySumDebit) }}
                        </td>
                        <td>
                          {{ accountingFormat(subsidiarySumCredit) }}
                        </td>
                        <td>
                          {{
                            accountingFormat(
                              subsidiarySumActualBeginningBalance
                            )
                          }}
                        </td>
                        <td>
                          {{ accountingFormat(subsidiarySumActualDebit) }}
                        </td>
                        <td>
                          {{ accountingFormat(subsidiarySumActualCredit) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
            <v-dialog v-model="dialogSubsidiary" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="account_code"
                          type="select-server"
                          :label="$t('fields.accountCode')"
                          v-model="editedSubsidiary.account_code"
                          :view="view"
                          :binds="{
                            apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`,
                            itemText: (item) => `${item.code} ${item.name}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="date"
                          type="datepicker"
                          :label="$t('fields.date')"
                          v-model="editedSubsidiary.date"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="journal"
                          type="text"
                          :label="$t('fields.journal')"
                          v-model="editedSubsidiary.journal"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="document_no"
                          type="text"
                          :label="$t('fields.docNo')"
                          v-model="editedSubsidiary.document_no"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_code"
                          type="text"
                          :label="$t('fields.partnerCode')"
                          v-model="editedSubsidiary.partner_code"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_name"
                          type="text"
                          :label="$t('fields.partnerName')"
                          v-model="editedSubsidiary.partner_name"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="partner_branch"
                          type="text"
                          :label="$t('fields.partnerBranch')"
                          v-model="editedSubsidiary.partner_branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="detail"
                          type="text"
                          :label="$t('fields.detail')"
                          v-model="editedSubsidiary.detail"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editedSubsidiary.note"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="beginning_balance"
                          type="number"
                          :label="$t('fields.beginningBalance')"
                          v-model="editedSubsidiary.beginning_balance"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="debit"
                          type="number"
                          :label="$t('fields.debit')"
                          v-model="editedSubsidiary.debit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="credit"
                          type="number"
                          :label="$t('fields.credit')"
                          v-model="editedSubsidiary.credit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_beginning_balance"
                          type="number"
                          :label="$t('fields.actualBeginningBalance')"
                          v-model="editedSubsidiary.actual_beginning_balance"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_debit"
                          type="number"
                          :label="$t('fields.actualDebit')"
                          v-model="editedSubsidiary.actual_debit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_credit"
                          type="number"
                          :label="$t('fields.actualCredit')"
                          v-model="editedSubsidiary.actual_credit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="branch"
                          type="text"
                          :label="$t('fields.branch')"
                          v-model="editedSubsidiary.branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedSubsidiary.business_unit"
                          name="business_unit"
                          type="select-server"
                          :label="$t('fields.businessUnit')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedSubsidiary.status"
                          name="status"
                          type="select-server"
                          :label="$t('fields.status')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-subsidiary-account-status'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedSubsidiary.dummy"
                          name="dummy"
                          type="switch"
                          :label="$t('fields.dummy')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onCloseSubsidiary">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveSubsidiary">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSubsidiaryImport" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-xlsx-import
                          @change="onSubsidiaryImportFileChange"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          color="blue darken-1"
                          text
                          :href="subsidiaryTemplateUrl"
                        >
                          Download Template
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ tempSubsidiaryImportStatus }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseImportSubsidiary"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onImportSubsidiaryDo"
                  >
                    {{ $t('btn.import') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSubsidiaryBias" max-width="700px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditSubsidiaryBiasCondition()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :is-selecteable="false"
                          :headers="subsidiaryBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :server-items="subsidiaryBiasItemItems"
                          :server-items-length="
                            subsidiaryBiasItemServerItemsLength
                          "
                          @server="getSubsidiaryBiasItems"
                          @edit="onEditSubsidiaryBiasCondition"
                          @delete="onDeleteSubsidiaryBiasCondition"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSubsidiaryBiasCondition" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="field"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.field')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-subsidiary-account-bias-field/'
                          }"
                          v-model="editedSubsidiaryBiasCondition.field"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="operator"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.operator')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-subsidiary-account-bias-operator/'
                          }"
                          v-model="editedSubsidiaryBiasCondition.operator"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <app-input
                          name="value1"
                          type="text"
                          :label="$t('fields.value1')"
                          :view="view"
                          v-model="editedSubsidiaryBiasCondition.value1"
                        />
                      </v-col>
                      <v-col cols="5">
                        <app-input
                          name="value2"
                          type="text"
                          :label="$t('fields.value2')"
                          :disabled="
                            editedSubsidiaryBiasCondition.operator !==
                              'Between' &&
                            editedSubsidiaryBiasCondition.operator !==
                              'Not Between'
                          "
                          :rules="{
                            required: (value) => {
                              if (
                                editedSubsidiaryBiasCondition.operator !==
                                  'Between' &&
                                editedSubsidiaryBiasCondition.operator !==
                                  'Not Between'
                              )
                                return true
                              if (value) return true

                              return 'You must enter a value2.'
                            }
                          }"
                          v-model="editedSubsidiaryBiasCondition.value2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="reason"
                          type="text"
                          :label="$t('fields.reason')"
                          :view="view"
                          v-model="editedSubsidiaryBiasCondition.reason"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryBiasConditionClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryBiasConditionSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSubsidiaryNonBias" max-width="600px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditSubsidiaryNonBiasCondition()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="subsidiaryNonBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="false"
                          :server-items="subsidiaryNonBiasItemItems"
                          :server-items-length="
                            subsidiaryNonBiasItemServerItemsLength
                          "
                          @server="getSubsidiaryNonBiasItems"
                          @edit="onEditSubsidiaryNonBiasCondition"
                          @delete="onDeleteSubsidiaryNonBiasCondition"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogSubsidiaryNonBiasCondition"
              max-width="500px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_size"
                          rules="required"
                          type="number"
                          :label="$t('fields.samplingSize')"
                          :view="view"
                          v-model="
                            editedSubsidiaryNonBiasCondition.sampling_size
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_method"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.samplingMethod')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-subsidiary-account-non-bias-sampling-method/'
                          }"
                          v-model="
                            editedSubsidiaryNonBiasCondition.sampling_method
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryNonBiasConditionClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSubsidiaryNonBiasConditionSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <!-- Stock Detail Outstanding Tab -->
          <v-tab-item v-if="isShowStockDetail">
            <v-toolbar flat>
              <v-toolbar-title>Stock Detail Outstanding</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onStockDetailBiasClick"
                >Bias
              </v-btn>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onStockDetailNonBiasClick"
                >Non-bias
              </v-btn>
              <v-btn
                color="error"
                dark
                v-show="mode === 'edit'"
                @click="onResetStockDetailBiasClick"
                >Reset
              </v-btn>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditStockDetail()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onImportStockDetail()"
                >
                  {{ $t('btn.import') }}
                </v-btn>
                <v-btn
                  v-show="stockDetailSelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteStockDetail(stockDetailSelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="stockDetailHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  v-model="stockDetailSelected"
                  :server-items="stockDetailItemItems"
                  :server-items-length="stockDetailItemServerItemsLength"
                  @server="getStockDetail"
                  @edit="onEditStockDetail"
                  @delete="onDeleteStockDetail"
                >
                  <template v-slot:[`item.subtask`]="{ item }">
                    <template v-if="item.subtask">
                      <router-link
                        :to="{
                          name: 'taskEdit',
                          params: { id: item.subtask.id },
                          query: {
                            task_stock_detail_id: item.id,
                            task_type: formData.task_type,
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from
                          }
                        }"
                        target="_blank"
                      >
                        <v-icon color="primary">mdi-link</v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'taskCreate',
                          query: {
                            task_stock_detail_id: item.id,
                            task_type: formData.task_type,
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from,
                            parent_task_id: $route.params.id
                          }
                        }"
                      >
                        <v-icon color="green">mdi-link</v-icon>
                      </router-link>
                    </template>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ accountingFormat(stockDetailSumProductBalance) }}
                        </td>
                        <td>
                          {{ accountingFormat(stockDetailSumProductValue) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {{
                            accountingFormat(stockDetailSumActualProductBalance)
                          }}
                        </td>
                        <td>
                          {{
                            accountingFormat(stockDetailSumActualProductValue)
                          }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
            <v-dialog v-model="dialogStockDetail" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_code"
                          type="text"
                          :label="$t('fields.productCode')"
                          v-model="editedStockDetail.product_code"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_name"
                          type="text"
                          :label="$t('fields.productName')"
                          v-model="editedStockDetail.product_name"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_barcode"
                          type="text"
                          :label="$t('fields.productBarcode')"
                          v-model="editedStockDetail.product_barcode"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_category"
                          type="text"
                          :label="$t('fields.productCategory')"
                          v-model="editedStockDetail.product_category"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_unit"
                          type="text"
                          :label="$t('fields.productUnit')"
                          v-model="editedStockDetail.product_unit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_cost"
                          type="number"
                          :label="$t('fields.productCostPerUnit')"
                          v-model="editedStockDetail.product_cost_per_unit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_balance"
                          type="number"
                          :label="$t('fields.productBalance')"
                          v-model="editedStockDetail.product_balance"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_value"
                          type="number"
                          :label="$t('fields.productValue')"
                          v-model="editedStockDetail.product_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_unit"
                          type="text"
                          :label="$t('fields.actualProductUnit')"
                          v-model="editedStockDetail.actual_product_unit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_cost"
                          type="number"
                          :label="$t('fields.actualProductCostPerUnit')"
                          v-model="
                            editedStockDetail.actual_product_cost_per_unit
                          "
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_balance"
                          type="number"
                          :label="$t('fields.actualProductBalance')"
                          v-model="editedStockDetail.actual_product_balance"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_value"
                          type="number"
                          :label="$t('fields.actualProductValue')"
                          v-model="editedStockDetail.actual_product_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="warehouse"
                          type="text"
                          :label="$t('fields.warehouse')"
                          v-model="editedStockDetail.warehouse"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="branch"
                          type="text"
                          :label="$t('fields.branch')"
                          v-model="editedStockDetail.branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockDetail.business_unit"
                          name="business_unit"
                          type="select-server"
                          :label="$t('fields.bu')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editedStockDetail.note"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockDetail.status"
                          name="status"
                          type="select-server"
                          :label="$t('fields.status')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-stock-detail-outstanding-status'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockDetail.dummy"
                          name="dummy"
                          type="switch"
                          :label="$t('fields.dummy')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onCloseStockDetail">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveStockDetail">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockDetailImport" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-xlsx-import
                          @change="onStockDetailImportFileChange"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          color="blue darken-1"
                          text
                          :href="stockDetailTemplateUrl"
                        >
                          Download Template
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ tempStockDetailImportStatus }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseImportStockDetail"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onImportStockDetailDo"
                  >
                    {{ $t('btn.import') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockDetailBias" max-width="700px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditStockDetailBiasCondition()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :is-selecteable="false"
                          :headers="stockDetailBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :server-items="stockDetailBiasItemItems"
                          :server-items-length="
                            stockDetailBiasItemServerItemsLength
                          "
                          @server="getStockDetailBiasItems"
                          @edit="onEditStockDetailBiasCondition"
                          @delete="onDeleteStockDetailBiasCondition"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogStockDetailBiasCondition"
              max-width="500px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="field"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.field')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-stock-detail-outstanding-bias-field/'
                          }"
                          v-model="editedStockDetailBiasCondition.field"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="operator"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.operator')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-stock-detail-outstanding-bias-operator/'
                          }"
                          v-model="editedStockDetailBiasCondition.operator"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <app-input
                          name="value1"
                          type="text"
                          :label="$t('fields.value1')"
                          :view="view"
                          v-model="editedStockDetailBiasCondition.value1"
                        />
                      </v-col>
                      <v-col cols="5">
                        <app-input
                          name="value2"
                          type="text"
                          :label="$t('fields.value2')"
                          :disabled="
                            editedStockDetailBiasCondition.operator !==
                            'Between'
                          "
                          :rules="{
                            required: (value) => {
                              if (
                                editedStockDetailBiasCondition.operator !==
                                'Between'
                              )
                                return true
                              if (value) return true

                              return 'You must enter a value2.'
                            }
                          }"
                          v-model="editedStockDetailBiasCondition.value2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="reason"
                          type="text"
                          :label="$t('fields.reason')"
                          :view="view"
                          v-model="editedStockDetailBiasCondition.reason"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailBiasConditionClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailBiasConditionSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockDetailNonBias" max-width="600px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditStockDetailNonBiasCondition()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="stockDetailNonBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="false"
                          :server-items="stockDetailNonBiasItemItems"
                          :server-items-length="
                            stockDetailNonBiasItemServerItemsLength
                          "
                          @server="getStockDetailNonBiasItems"
                          @edit="onEditStockDetailNonBiasCondition"
                          @delete="onDeleteStockDetailNonBiasCondition"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogStockDetailNonBiasCondition"
              max-width="500px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_size"
                          rules="required"
                          type="number"
                          :label="$t('fields.samplingSize')"
                          :view="view"
                          v-model="
                            editedStockDetailNonBiasCondition.sampling_size
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_method"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.method')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-stock-detail-outstanding-non-bias-sampling-method/'
                          }"
                          v-model="
                            editedStockDetailNonBiasCondition.sampling_method
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailNonBiasConditionClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockDetailNonBiasConditionSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <!-- combined in and out tab -->
          <v-tab-item v-if="isShowCombinedInOut">
            <v-toolbar flat>
              <v-toolbar-title>Combined In and Out</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onCombinedInOutBiasClick"
                >Bias
              </v-btn>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onCombinedInOutNonBiasClick"
                >Non-bias
              </v-btn>
              <v-btn
                color="error"
                dark
                v-show="mode === 'edit'"
                @click="onResetCombinedInOutBiasClick"
                >Reset
              </v-btn>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditCombinedInOut"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onImportCombinedInOut()"
                >
                  {{ $t('btn.import') }}
                </v-btn>
                <v-btn
                  v-show="combinedInOutSelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteCombinedInOut(combinedInOutSelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-dialog v-model="dialogCombinedInOut" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_code"
                          type="text"
                          :label="$t('fields.productCode')"
                          v-model="editingCombinedInOut.product_code"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_name"
                          type="text"
                          :label="$t('fields.productName')"
                          v-model="editingCombinedInOut.product_name"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_barcode"
                          type="text"
                          :label="$t('fields.productBarcode')"
                          v-model="editingCombinedInOut.product_barcode"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_category"
                          type="text"
                          :label="$t('fields.productCategory')"
                          v-model="editingCombinedInOut.product_category"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_unit"
                          type="text"
                          :label="$t('fields.productUnit')"
                          v-model="editingCombinedInOut.product_unit"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="doc_no"
                          type="text"
                          :label="$t('fields.docNo')"
                          v-model="editingCombinedInOut.doc_no"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="doc_date"
                          type="datepicker"
                          :label="$t('fields.docDate')"
                          v-model="editingCombinedInOut.doc_date"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="doc_description"
                          type="text"
                          :label="$t('fields.docDescription')"
                          v-model="editingCombinedInOut.doc_description"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="vendor_code"
                          type="text"
                          :label="$t('fields.vendorCode')"
                          v-model="editingCombinedInOut.vendor_code"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="vendor_name"
                          type="text"
                          :label="$t('fields.vendorName')"
                          v-model="editingCombinedInOut.vendor_name"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="vendor_branch"
                          type="text"
                          :label="$t('fields.vendorBranch')"
                          v-model="editingCombinedInOut.vendor_branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_price"
                          type="number"
                          :label="$t('fields.productPrice')"
                          v-model="editingCombinedInOut.product_price"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_qty"
                          type="number"
                          :label="$t('fields.productQty')"
                          v-model="editingCombinedInOut.product_qty"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_value"
                          type="number"
                          :label="$t('fields.productValue')"
                          v-model="editingCombinedInOut.product_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="initial_value"
                          type="number"
                          :label="$t('fields.initialValue')"
                          v-model="editingCombinedInOut.initial_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_price"
                          type="number"
                          :label="$t('fields.actualProductPrice')"
                          v-model="editingCombinedInOut.actual_product_price"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_qty"
                          type="number"
                          :label="$t('fields.actualProductQty')"
                          v-model="editingCombinedInOut.actual_product_qty"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_value"
                          type="number"
                          :label="$t('fields.actualProductValue')"
                          v-model="editingCombinedInOut.actual_product_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_initial_value"
                          type="number"
                          :label="$t('fields.actualInitialValue')"
                          v-model="editingCombinedInOut.actual_initial_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="warehouse"
                          type="text"
                          :label="$t('fields.warehouse')"
                          v-model="editingCombinedInOut.warehouse"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="branch"
                          type="text"
                          :label="$t('fields.branch')"
                          v-model="editingCombinedInOut.branch"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingCombinedInOut.business_unit"
                          name="business_unit"
                          type="select-server"
                          :label="$t('fields.businessUnit')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editingCombinedInOut.note"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingCombinedInOut.status"
                          name="status"
                          type="select-server"
                          :label="$t('fields.status')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-status/'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editingCombinedInOut.dummy"
                          name="dummy"
                          type="switch"
                          :label="$t('fields.dummy')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseCombinedInOut"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onSaveCombinedInOut"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCombinedInOutImport" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-xlsx-import
                          @change="onCombinedInOutImportFileChange"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          color="blue darken-1"
                          text
                          :href="combinedInOutTemplateUrl"
                        >
                          Download Template
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ tempCombinedInOutImportStatus }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseImportCombinedInOut"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onImportCombinedInOutDo"
                  >
                    {{ $t('btn.import') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCombinedInOutBias" max-width="700px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditCombinedInOutBias()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="combinedInOutBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="true"
                          :server-items="combinedInOutBiases"
                          :server-items-length="combinedInOutBiasesCount"
                          @server="getCombinedInOutBiases"
                          @edit="onEditCombinedInOutBias"
                          @delete="onDeleteCombinedInOutBias"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCombinedInOutBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCombinedInOutBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditCombinedInOutBias" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="field"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.field')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-combined-in-out-bias-field/'
                          }"
                          v-model="editingCombinedInOutBias.field"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="operator"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.operator')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-bias-operator/'
                          }"
                          v-model="editingCombinedInOutBias.operator"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <app-input
                          name="value1"
                          type="text"
                          :label="$t('fields.value1')"
                          :view="view"
                          v-model="editingCombinedInOutBias.value1"
                        />
                      </v-col>
                      <v-col cols="5">
                        <app-input
                          name="value2"
                          type="text"
                          :label="$t('fields.value2')"
                          :disabled="
                            editingCombinedInOutBias.operator !== 'Between' &&
                            editingCombinedInOutBias.operator !== 'Not Between'
                          "
                          :rules="{
                            required: (value) => {
                              if (
                                editingCombinedInOutBias.operator !==
                                  'Between' &&
                                editingCombinedInOutBias.operator !==
                                  'Not Between'
                              )
                                return true
                              if (value) return true

                              return 'You must enter a value2.'
                            }
                          }"
                          v-model="editingCombinedInOutBias.value2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="reason"
                          type="text"
                          :label="$t('fields.reason')"
                          :view="view"
                          v-model="editingCombinedInOutBias.reason"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditCombinedInOutBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditCombinedInOutBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCombinedInOutNonBias" max-width="600px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditCombinedInOutNonBias"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="combinedInOutNonBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="true"
                          :server-items="combinedInOutNonBiases"
                          :server-items-length="combinedInOutNonBiasesCount"
                          @server="getCombinedInOutNonBiases"
                          @edit="onEditCombinedInOutNonBias"
                          @delete="onDeleteCombinedInOutNonBias"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCombinedInOutNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCombinedInOutNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogEditCombinedInOutNonBias"
              max-width="500px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_size"
                          rules="required"
                          type="number"
                          :label="$t('fields.samplingSize')"
                          :view="view"
                          v-model="editingCombinedInOutNonBias.sampling_size"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_method"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.samplingMethod')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-debit-non-bias-sampling-method/'
                          }"
                          v-model="editingCombinedInOutNonBias.sampling_method"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditCombinedInOutNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onEditCombinedInOutNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="combinedInOutHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  v-model="combinedInOutSelected"
                  :server-items="combinedInOuts"
                  :server-items-length="combinedInOutsCount"
                  @server="getCombinedInOuts"
                  @edit="onEditCombinedInOut"
                  @delete="onDeleteCombinedInOut"
                >
                  <template v-slot:item.subtask="{ item }">
                    <template v-if="item.subtask">
                      <router-link
                        :to="{
                          name: 'taskEdit',
                          params: { id: item.subtask.id },
                          query: {
                            task_combined_in_out_id: item.id,
                            task_type: 'DCD',
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from
                          }
                        }"
                        target="_blank"
                      >
                        <v-icon color="primary">mdi-link</v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'taskCreate',
                          query: {
                            task_combined_in_out_id: item.id,
                            task_type: 'DCD',
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from,
                            parent_task_id: $route.params.id
                          }
                        }"
                      >
                        <v-icon color="green">mdi-link</v-icon>
                      </router-link>
                    </template>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td>
                          {{ accountingFormat(combinedInOutsSumProductValue) }}
                        </td>
                        <td>
                          {{ accountingFormat(combinedInOutsSumInitialValue) }}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {{
                            accountingFormat(
                              combinedInOutsSumActualProductValue
                            )
                          }}
                        </td>
                        <td>
                          {{
                            accountingFormat(
                              combinedInOutsSumActualInitialValue
                            )
                          }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Stock Value Outstanding Tab -->
          <v-tab-item v-if="isShowStockValue">
            <v-toolbar flat>
              <v-toolbar-title>Stock Value Outstanding</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onStockValueBiasClick"
                >Bias
              </v-btn>
              <v-btn
                color="primary"
                dark
                v-show="mode === 'edit'"
                @click="onStockValueNonBiasClick"
                >Non-bias
              </v-btn>
              <v-btn
                color="error"
                dark
                v-show="mode === 'edit'"
                @click="onResetStockValueBiasClick"
                >Reset
              </v-btn>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditStockValue()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onImportStockValue()"
                >
                  {{ $t('btn.import') }}
                </v-btn>
                <v-btn
                  v-show="stockValueSelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteStockValue(stockValueSelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="stockValueHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  v-model="stockValueSelected"
                  :server-items="stockValueItemItems"
                  :server-items-length="stockValueItemServerItemsLength"
                  @server="getStockValue"
                  @edit="onEditStockValue"
                  @delete="onDeleteStockValue"
                >
                  <template v-slot:[`item.subtask`]="{ item }">
                    <template v-if="item.subtask">
                      <router-link
                        :to="{
                          name: 'taskEdit',
                          params: { id: item.subtask.id },
                          query: {
                            task_stock_value_id: item.id,
                            task_type: formData.task_type,
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from
                          }
                        }"
                        target="_blank"
                      >
                        <v-icon color="primary">mdi-link</v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'taskCreate',
                          query: {
                            task_stock_value_id: item.id,
                            task_type: formData.task_type,
                            audit_plan_id: $route.query.audit_plan_id,
                            audit_id: $route.query.audit_id,
                            from: $route.query.from,
                            parent_task_id: $route.params.id
                          }
                        }"
                      >
                        <v-icon color="green">mdi-link</v-icon>
                      </router-link>
                    </template>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{ accountingFormat(stockValueSumProductValue) }}
                        </td>
                        <td>
                          {{
                            accountingFormat(stockValueSumActualProductValue)
                          }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
            <v-dialog v-model="dialogStockValue" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="warehouse"
                          type="text"
                          :label="$t('fields.warehouse')"
                          v-model="editedStockValue.warehouse"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockValue.branch"
                          name="branch"
                          type="select-server"
                          :label="$t('fields.branch')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/contact-branch/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockValue.business_unit"
                          name="business_unit"
                          type="select-server"
                          :label="$t('fields.bu')"
                          :view="view"
                          :binds="{
                            apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="product_value"
                          type="number"
                          :label="$t('fields.productValue')"
                          v-model="editedStockValue.product_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="actual_product_value"
                          type="number"
                          :label="$t('fields.actual')"
                          v-model="editedStockValue.actual_product_value"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          v-model="editedStockValue.note"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockValue.status"
                          name="status"
                          type="select-server"
                          :label="$t('fields.status')"
                          :view="view"
                          :binds="{
                            apiUrl: 'audit/task-stock-value-outstanding-status'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          v-model="editedStockValue.dummy"
                          name="dummy"
                          type="switch"
                          :label="$t('fields.dummy')"
                          :view="view"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onCloseStockValue">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveStockValue">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockValueImport" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-xlsx-import
                          @change="onStockValueImportFileChange"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <v-btn
                          color="blue darken-1"
                          text
                          :href="stockValueTemplateUrl"
                        >
                          Download Template
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ tempStockValueImportStatus }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onCloseImportStockValue"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onImportStockValueDo"
                  >
                    {{ $t('btn.import') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockValueBias" max-width="700px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditStockValueBiasCondition()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :is-selecteable="false"
                          :headers="stockValueBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :server-items="stockValueBiasItemItems"
                          :server-items-length="
                            stockValueBiasItemServerItemsLength
                          "
                          @server="getStockValueBiasItems"
                          @edit="onEditStockValueBiasCondition"
                          @delete="onDeleteStockValueBiasCondition"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockValueBiasCondition" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="field"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.field')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-stock-value-outstanding-bias-field/'
                          }"
                          v-model="editedStockValueBiasCondition.field"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="operator"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.operator')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-stock-value-outstanding-bias-operator/'
                          }"
                          v-model="editedStockValueBiasCondition.operator"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <app-input
                          name="value1"
                          type="text"
                          :label="$t('fields.value1')"
                          :view="view"
                          v-model="editedStockValueBiasCondition.value1"
                        />
                      </v-col>
                      <v-col cols="5">
                        <app-input
                          name="value2"
                          type="text"
                          :label="$t('fields.value2')"
                          :disabled="
                            editedStockValueBiasCondition.operator !==
                              'Between' &&
                            editedStockValueBiasCondition.operator !==
                              'Not Between'
                          "
                          :rules="{
                            required: (value) => {
                              if (
                                editedStockValueBiasCondition.operator !==
                                  'Between' &&
                                editedStockValueBiasCondition.operator !==
                                  'Not Between'
                              )
                                return true
                              if (value) return true

                              return 'You must enter a value2.'
                            }
                          }"
                          v-model="editedStockValueBiasCondition.value2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="reason"
                          type="text"
                          :label="$t('fields.reason')"
                          :view="view"
                          v-model="editedStockValueBiasCondition.reason"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueBiasConditionClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueBiasConditionSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogStockValueNonBias" max-width="600px">
              <v-card>
                <v-toolbar flat>
                  <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      v-show="mode === 'edit'"
                      @click="onEditStockValueNonBiasCondition()"
                    >
                      {{ $t('btn.newItem') }}
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          server-side
                          model="task"
                          app="audit"
                          :headers="stockValueNonBiasHeaders"
                          :hide-edit="mode !== 'edit'"
                          :hide-delete="mode !== 'edit'"
                          :is-selecteable="false"
                          :server-items="stockValueNonBiasItemItems"
                          :server-items-length="
                            stockValueNonBiasItemServerItemsLength
                          "
                          @server="getStockValueNonBiasItems"
                          @edit="onEditStockValueNonBiasCondition"
                          @delete="onDeleteStockValueNonBiasCondition"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueNonBiasClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueNonBiasSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogStockValueNonBiasCondition"
              max-width="500px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_size"
                          rules="required"
                          type="number"
                          :label="$t('fields.samplingSize')"
                          :view="view"
                          v-model="
                            editedStockValueNonBiasCondition.sampling_size
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10">
                        <app-input
                          name="sampling_method"
                          rules="required"
                          type="select-server"
                          :label="$t('fields.method')"
                          :view="view"
                          :binds="{
                            apiUrl:
                              'audit/task-stock-value-outstanding-non-bias-sampling-method/'
                          }"
                          v-model="
                            editedStockValueNonBiasCondition.sampling_method
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueNonBiasConditionClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onStockValueNonBiasConditionSave"
                  >
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <!-- Standard Man Days -->
          <v-tab-item>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('menu.standardManDay') }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <slot name="actions">
                <slot name="prefix-actions" />
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditManDay()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
                <v-btn
                  v-show="manDaySelected.length && mode === 'edit'"
                  color="error"
                  @click="onDeleteManDay(manDaySelected)"
                >
                  {{ $t('btn.delete') }}
                </v-btn>
              </slot>
            </v-toolbar>
            <v-dialog v-model="dialogManDay" max-width="600px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <app-input
                          name="position"
                          :label="$t('fields.position')"
                          :view="view"
                          type="select-server"
                          v-model="editingManDay.position"
                          :binds="{
                            apiUrl:
                              'audit/master-position/?active=true&state=approved'
                          }"
                        />
                      </v-col>
                      <v-col cols="6">
                        <app-input
                          name="man_day"
                          rules="required"
                          :label="$t('fields.manDay')"
                          :view="view"
                          :handleBlur="
                            () =>
                              onTimeHrsBlur(
                                'editingManDay',
                                'man_day',
                                editingManDay.man_day
                              )
                          "
                          :handleKeyDown="onTimeHrsKeyDow"
                          v-model="editingManDay.man_day"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <app-input
                          v-model="editingManDay.note"
                          name="note"
                          type="text"
                          :label="$t('fields.note')"
                          :view="view"
                      /></v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onCloseManDay">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="onSaveManDay">
                    {{ $t('btn.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="manDayHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  v-model="manDaySelected"
                  :server-items="manDaysItems"
                  :server-items-length="manDaysItemsCount"
                  @server="getManDays"
                  @edit="onEditManDay"
                  @delete="onDeleteManDay"
                ></app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Related COA Tab -->
          <v-tab-item v-if="formData?.audit_id != null">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="relatedCOAItemHeader"
                  :serverItems="relatedCOAItemItems"
                  :clientItems="relatedCOAItemItems"
                  :loading="loading"
                  :server-items-length="relatedCOAItemServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  :hide-edit="mode !== 'edit'"
                  v-model="relatedCOASelected"
                  @server="getRelatedCOAItemItems"
                  @delete="onDeleteRelatedCOAItemItem"
                  @edit="onEditRelatedCOAItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Related COA</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog
                            v-model="dialogRelatedCOAItem"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="
                                    mode === 'edit' &&
                                    relatedCOAItemServerItemsLength === 0
                                  "
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="
                                    relatedCOASelected.length && mode === 'edit'
                                  "
                                  color="error"
                                  @click="
                                    onDeleteRelatedCOAItemItem(
                                      relatedCOASelected
                                    )
                                  "
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>
                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12">
                                      <app-input
                                        name="chart_of_account"
                                        type="select-server"
                                        :label="$t('fields.chartOfAccount')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`,
                                          itemText: (item) =>
                                            `${item.code} ${item.name}`
                                        }"
                                        v-model="
                                          editedRelatedCOA.chart_of_account_detail_id
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeRelatedCOAItem"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveRelatedCOAItem"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Sum Related COA Tab -->
          <v-tab-item v-if="formData?.audit_id != null">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="sumRelatedCOAItemHeader"
                  :loading="loading"
                  :is-selecteable="false"
                  :server-items="sumRelatedCOAItemItems"
                  :server-items-length="sumRelatedCOAItemServerItemsLength"
                  @server="getSumRelatedCOAItemItems"
                  hide-edit
                  hide-delete
                  :is-expand-server="true"
                  :single-expand="true"
                  :expand-server-headers="subSumRelatedCOAItemHeader"
                  :expand-items="subSumRelatedCOAItemItems"
                  :expand-items-length="subSumRelatedCOAItemServerItemsLength"
                  @item-expanded="getSubSumRelatedCOAItemItems"
                  @expand-view="onEditTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >Summarize Related CoA</v-toolbar-title
                          >
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'
import AppDateTimePicker from '@components/AppDateTimePicker.vue'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import readXlsxFile from 'read-excel-file'
import { accounting, hoursMinutes, percentFormat } from '@utils/number-format'
import Papa from 'papaparse'
import { stripHtml } from '@utils/string-format'
import { DateTime } from 'luxon'

export default {
  name: 'task',
  components: {
    AppXlsxImport,
    AppInput,
    AppFormView,
    AppTable,
    AppDateTimePicker
  },
  data() {
    return {
      user_id: null,
      formData: {
        state: 'draft'
      },
      tab: null,
      loading: false,
      approveItems: [
        'approve_name',
        'approve_task',
        'approve_contact_id',
        'approve_parent_task_id',
        'approve_sequence',
        'approve_audit_year',
        'approve_audit_period',
        'approve_deadline',
        'approve_accounting_period',
        'approve_assign_date',
        'approve_assign_to',
        'approve_task_type'
      ],
      requiredDocumentItems: [],
      requiredDocumentClientItems: [],
      requiredDocumentSelected: [],
      requiredDocumentItemsLength: 0,
      dialogRequiredDocument: false,
      taskActivityProfileItems: [],
      taskActivityProfileClientItems: [],
      taskActivityProfileSelected: [],
      taskActivityProfileItemsLength: 0,
      dialogTaskActivityProfile: false,
      taskRiskProfileItems: [],
      taskRiskProfileClientItems: [],
      taskRiskProfileSelected: [],
      taskRiskProfileItemsLength: 0,
      dialogTaskRiskProfile: false,
      taskQuestionnaireItems: [],
      taskQuestionnaireClientItems: [],
      taskQuestionnaireSelected: [],
      taskQuestionnaireItemsLength: 0,
      dialogTaskQuestionnaire: false,
      isNewQuestionnaireClicked: false,
      taskPropertyAndAgreementItems: [],
      taskPropertyAndAgreementClientItems: [],
      taskPropertyAndAgreementSelected: [],
      taskPropertyAndAgreementItemsLength: 0,
      dialogTaskPropertyAndAgreement: false,
      editedPropertyAndAgreementItem: {},
      groupNameUrl: null,
      apiMethod: 'post',
      editedItem: {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      },
      parentTaskUrl: null,
      subtasks: [],
      subtasksCount: 0,
      grandChildSubtasks: {},
      grandChildSubtasksLength: {},
      dialogNewTask: false,
      newTaskTemplate: null,
      conclusionItems: [],
      conclusionItemsCount: 0,
      dialogConclusion: false,
      dialogAdjustment: false,
      editingConclusion: {},
      previousPage: null,
      objectiveItems: [],
      objectiveItemsCount: 0,
      dialogObjective: false,
      editingObjective: {},
      adjustments: [],
      adjustmentsCount: 0,
      editingAdjustment: {},
      dialogAdjustmentLine: false,
      editingAdjustmentLine: {},
      adjustmentLinesFromSubtasks: [],
      dialogTimesheetItem: false,
      editedTimesheetItemItem: [],
      memberTimesheetItems: [],
      timesheetByPositions: [],
      timesheetByPositionsCount: 0,
      timesheetByPositionSumStandardManDay: 0,
      timesheetByPositionSumAssignedManDay: 0,
      timesheetByPositionSumUnassigned: 0,
      timesheetByPositionSumAssignedProgress: 0,
      timesheetByPositionSumFinishedAssignedManDay: 0,
      timesheetByPositionSumActualManDay: 0,
      timesheetByPositionSumVariance: 0,
      timesheetByPositionSumTaskPerformance: 0,
      timesheetByPositionSumWorkProgress: 0,
      timesheetItemItems: [],
      timesheetItemServerItemsLength: 0,
      timesheetSumAssignedManDay: 0,
      timesheetSumActualManDay: 0,
      timesheetByMembers: [],
      timesheetByMembersCount: 0,
      timesheetByMemberSumAssignedManDay: 0,
      timesheetByMemberSumWeight: 0,
      timesheetByMemberSumFinishedAssignedManDay: 0,
      timesheetByMemberSumActualManDay: 0,
      timesheetByMemberSumVariance: 0,
      timesheetByMemberSumTaskPerformance: 0,
      timesheetByMemberSumWorkProgress: 0,
      timesheetByMemberIdItems: {},
      timesheetByMemberIdItemsCount: {},
      selected: [],
      dialogDebitSum: false,
      debitSummaries: [],
      debitSummariesCount: 0,
      debitSummarySelected: [],
      debitSummariesSumBalance: 0,
      debitSummariesSumActual: 0,
      editingDebitSum: {},
      dialogDebitSumImport: false,
      debitSumImportForm: {},
      tempDebitSumImports: [],
      debitSummaryBiases: [],
      debitSummaryBiasesCount: 0,
      dialogDebitSumBias: false,
      dialogEditDebitSumBias: false,
      editingDebitSumBias: {},
      dialogEditDebitSumNonBias: false,
      debitSummaryNonBiases: [],
      debitSummaryNonBiasesCount: 0,
      dialogDebitSumNonBias: false,
      editingDebitSumNonBias: {},
      dialogDebitDetail: false,
      debitDetails: [],
      debitDetailsCount: 0,
      debitDetailSelected: [],
      debitDetailsSumDocAmount: 0,
      debitDetailsSumPaidAmount: 0,
      debitDetailsSumRemaining: 0,
      debitDetailsSumActual: 0,
      editingDebitDetail: {},
      dialogDebitDetailImport: false,
      debitDetailImportForm: {},
      tempDebitDetailImports: [],
      debitDetailBiases: [],
      debitDetailBiasesCount: 0,
      dialogDebitDetailBias: false,
      dialogEditDebitDetailBias: false,
      editingDebitDetailBias: {},
      dialogEditDebitDetailNonBias: false,
      debitDetailNonBiases: [],
      debitDetailNonBiasesCount: 0,
      dialogDebitDetailNonBias: false,
      editingDebitDetailNonBias: {},
      subsidiarySelected: [],
      editedSubsidiary: [],
      editedSubsidiaryBiasCondition: [],
      editedSubsidiaryNonBiasCondition: [],
      dialogSubsidiary: false,
      dialogSubsidiaryImport: false,
      dialogSubsidiaryBias: false,
      dialogSubsidiaryBiasCondition: false,
      dialogSubsidiaryNonBias: false,
      dialogSubsidiaryNonBiasCondition: false,
      subsidiaryItemItems: [],
      subsidiaryItemServerItemsLength: 0,
      subsidiarySumDebit: 0,
      subsidiarySumCredit: 0,
      subsidiarySumActualDebit: 0,
      subsidiarySumActualCredit: 0,
      subsidiarySumBeginningBalance: 0,
      subsidiarySumActualBeginningBalance: 0,
      tempSubsidiaryImports: [],
      subsidiaryImportForm: {},
      subsidiaryBiasItemItems: [],
      subsidiaryBiasItemServerItemsLength: 0,
      subsidiaryNonBiasItemItems: [],
      subsidiaryNonBiasItemServerItemsLength: 0,
      dialogCombinedInOut: false,
      combinedInOuts: [],
      combinedInOutsCount: 0,
      combinedInOutSelected: [],
      combinedInOutsSumProductValue: 0,
      combinedInOutsSumInitialValue: 0,
      combinedInOutsSumActualProductValue: 0,
      combinedInOutsSumActualInitialValue: 0,
      editingCombinedInOut: {},
      dialogCombinedInOutImport: false,
      combinedInOutImportForm: {},
      tempCombinedInOutImports: [],
      combinedInOutBiases: [],
      combinedInOutBiasesCount: 0,
      dialogCombinedInOutBias: false,
      dialogEditCombinedInOutBias: false,
      editingCombinedInOutBias: {},
      dialogEditCombinedInOutNonBias: false,
      combinedInOutNonBiases: [],
      combinedInOutNonBiasesCount: 0,
      dialogCombinedInOutNonBias: false,
      editingCombinedInOutNonBias: {},
      stockDetailSelected: [],
      editedStockDetail: [],
      editedStockDetailBiasCondition: [],
      editedStockDetailNonBiasCondition: [],
      dialogStockDetail: false,
      dialogStockDetailImport: false,
      dialogStockDetailBias: false,
      dialogStockDetailBiasCondition: false,
      dialogStockDetailNonBias: false,
      dialogStockDetailNonBiasCondition: false,
      stockDetailItemItems: [],
      stockDetailItemServerItemsLength: 0,
      stockDetailSumProductBalance: 0,
      stockDetailSumProductValue: 0,
      stockDetailSumActualProductBalance: 0,
      stockDetailSumActualProductValue: 0,
      tempStockDetailImports: [],
      stockDetailImportForm: {},
      stockDetailBiasItemItems: [],
      stockDetailBiasItemServerItemsLength: 0,
      stockDetailNonBiasItemItems: [],
      stockDetailNonBiasItemServerItemsLength: 0,
      stockValueSelected: [],
      editedStockValue: [],
      editedStockValueBiasCondition: [],
      editedStockValueNonBiasCondition: [],
      dialogStockValue: false,
      dialogStockValueImport: false,
      dialogStockValueBias: false,
      dialogStockValueBiasCondition: false,
      dialogStockValueNonBias: false,
      dialogStockValueNonBiasCondition: false,
      stockValueItemItems: [],
      stockValueItemServerItemsLength: 0,
      tempStockValueImports: [],
      stockValueImportForm: {},
      stockValueBiasItemItems: [],
      stockValueBiasItemServerItemsLength: 0,
      stockValueNonBiasItemItems: [],
      stockValueNonBiasItemServerItemsLength: 0,
      stockValueSumProductValue: 0,
      stockValueSumActualProductValue: 0,
      editingQuestionnaireLine: {},
      editingQuestionnaire: {},
      dialogQuestionnaireLine: false,
      taskQuestionnaireId: null,
      taskQuestionnaireType: null,
      dialogQuestionAnswer: false,
      questionLineUrl: null,
      answerItem: [],
      selectedItem: [],
      answerScore: 0,
      taskQuestionnaireAnswer: null,
      editingManDay: {},
      dialogManDay: false,
      manDaySelected: [],
      manDaysItems: [],
      manDaysItemsCount: 0,
      currentTaskTemplateHeader: null,
      relatedCOASelected: [],
      editedRelatedCOA: [],
      relatedCOAItemItems: [],
      relatedCOAItemServerItemsLength: 0,
      dialogRelatedCOAItem: false,
      sumRelatedCOAItemItems: [],
      sumRelatedCOAItemServerItemsLength: 0,
      subSumRelatedCOAItemItems: {},
      subSumRelatedCOAItemServerItemsLength: {},
      auditId: null
    }
  },
  watch: {
    tab: function (val) {
      // index of adjustment tab is 9
      if (val === 9) {
        this.getAdjustments()
      }
    },
    'editedItem.questionAnswer': {
      handler() {
        this.totalScore()
      },
      deep: true // This ensures the watcher triggers even if the objects inside the array are mutated
    },
    selectedItem: {
      handler() {
        this.totalScore()
      },
      deep: true // This ensures the watcher triggers even if the objects inside the array are mutated
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    isParentTaskDisabled() {
      return !!this.$route.query.parent_task_id
    },
    computedSelectedItems() {
      if (
        this.taskQuestionnaireType === 'MultipleChoice' ||
        this.taskQuestionnaireType === 'Checkbox'
      ) {
        if (
          !this.taskQuestionnaireAnswer ||
          !this.taskQuestionnaireAnswer[0].selected_task_questionnaire
        ) {
          return []
        }
        return this.taskQuestionnaireAnswer[0].selected_task_questionnaire.map(
          (item) => ({
            id: item.id,
            points: item.points
          })
        )
      }
      return []
    },
    sumPoints() {
      return this.sumPointsItem('total_points')
    },
    sumScore() {
      return this.sumScoreItem('score')
    },
    requiredDocumentHeader() {
      return [
        {
          text: this.$t('fields.requiredDocument'),
          value: 'document_template_id.name'
        },
        {
          text: this.$t('fields.name'),
          value: 'personal_document_id.document_name'
        },
        {
          text: this.$t('fields.link'),
          value: 'personal_document_id',
          hideFilter: true
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'contact_business_unit_id.business_unit_id.name'
        },
        {
          text: this.$t('fields.branchName'),
          value: 'contact_branch_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    subtaskHeaders() {
      return [
        {
          text: this.$t('fields.auditPhase'),
          value: 'audit_phase',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.sectionName'),
          value: 'name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.prepareBy'),
          value: 'prepare_by.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.reviewBy'),
          value: 'review_by.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    conclusionHeaders() {
      return [
        {
          text: this.$t('fields.report'),
          value: 'report_type',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.conclusion'),
          value: 'conclusion',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    taskActivityProfileHeader() {
      return [
        {
          text: this.$t('fields.activityProfile'),
          value: 'activity_profile_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    conclusionReportTypes() {
      return [
        'Auditor Report',
        'Note to Financial Statement',
        'Management Report',
        'Follow Up Report'
      ]
    },
    taskRiskProfileHeader() {
      return [
        {
          text: this.$t('fields.riskProfile'),
          value: 'risk_profile_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    taskQuestionnaireHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.question'),
          value: 'question',
          hideFilter: true
        },
        {
          text: this.$t('fields.questionType'),
          value: 'question_type',
          hideFilter: true
        },
        {
          text: this.$t('fields.points'),
          value: 'total_points',
          hideFilter: true
        },
        {
          text: this.$t('fields.score'),
          value: 'answers.score',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    questionnaireLineHeaders() {
      return [
        {
          text: this.$t('fields.choice'),
          value: 'choice',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.points'),
          value: 'points',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    taskPropertyAndAgreementHeader() {
      return [
        {
          text: this.$t('fields.propertyAndAgreement'),
          value: 'property_and_agreement'
        },
        {
          text: this.$t('fields.groupName'),
          value: 'group_name'
        },
        {
          text: this.$t('fields.link'),
          value: 'group_link',
          link: true
        },
        {
          text: this.$t('fields.branchCode'),
          value: 'contact_branch_id.branch_code'
        },
        {
          text: this.$t('fields.branchName'),
          value: 'contact_branch_id.name'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit_id.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    objectiveHeaders() {
      return [
        {
          text: this.$t('fields.objective'),
          value: 'objective.name',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.chartOfAccountName'),
          value: 'chart_of_account_details',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    adjustmentHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          groupable: false,
          sortable: false,
          hideFilter: true
        }
      ]
    },
    adjustmentTypeItems() {
      return [
        { label: 'Adjustment', value: 'Adjustment' },
        { label: 'Reclassification', value: 'Reclassification' }
      ]
    },
    adjustmentLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    editingAdjustmentTotalDebit() {
      return this.calcAdjustmentTotalDebit(this.editingAdjustment)
    },
    editingAdjustmentTotalCredit() {
      return this.calcAdjustmentTotalCredit(this.editingAdjustment)
    },
    editingAdjustmentIsBalanced() {
      return this.calcAdjustmentIsBalanced(this.editingAdjustment)
    },
    adjustmentLinesFromSubtasksTotalDebit() {
      return this.privateCalcAdjustmentTotalDebit(
        this.adjustmentLinesFromSubtasks
      )
    },
    adjustmentLinesFromSubtasksTotalCredit() {
      return this.privateCalcAdjustmentTotalCredit(
        this.adjustmentLinesFromSubtasks
      )
    },
    adjustmentLinesFromSubtasksHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail.code',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail.name',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.referenceTask'),
          value: 'task_adjustment__task__reference',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          groupable: false,
          sortable: true,
          hideFilter: true
        }
      ]
    },
    timesheetByPositionHeaders() {
      return [
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'position__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.unassigned'),
          value: 'unassigned',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedProgress'),
          value: 'assigned_progress',
          hideFilter: true,
          sortable: false,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    timesheetByMemberHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'member_name',
          hideFilter: false,
          filterName: 'audit_plan_member__contact_id__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'total_standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'total_expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'total_weight',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'total_finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'total_actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'total_variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'total_task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'total_work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    timesheetByMemberIdHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'member_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'task_standard_man_day__position_id__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'total_standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'total_expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'total_weight',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'total_finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'total_actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'total_variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'total_task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'total_work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    timesheetItemHeader() {
      return [
        {
          text: this.$t('fields.team'),
          value: 'audit_plan_member.team_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignedBy'),
          value: 'assigned_by.contact_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.taskPosition'),
          value: 'task_standard_man_day.position.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'audit_plan_member.contact_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.projectPosition'),
          value: 'audit_plan_member.position.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignedManDay'),
          value: 'expected_man_day_converted',
          hideFilter: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'weight',
          hideFilter: true,
          sortable: false,
          percentFormat: true
        },
        {
          text: this.$t('fields.assignedDate'),
          value: 'date',
          hideFilter: true,
          dateTimeFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day_converted',
          hideFilter: true
        },
        {
          text: this.$t('fields.actualStartDate'),
          value: 'actual_start_date',
          hideFilter: true,
          dateTimeFormat: true
        },
        {
          text: this.$t('fields.actualEndDate'),
          value: 'actual_end_date',
          hideFilter: true,
          dateTimeFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'work_progress',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitSummaryHeaders() {
      return [
        {
          text: this.$t('fields.partnerCode'),
          value: 'partner_code'
        },
        {
          text: this.$t('fields.partnerName'),
          value: 'partner_name'
        },
        {
          text: this.$t('fields.partnerBranch'),
          value: 'partner_branch'
        },
        {
          text: this.$t('fields.partnerAddress'),
          value: 'partner_address'
        },
        {
          text: this.$t('fields.partnerAttnPerson'),
          value: 'partner_attn_person'
        },
        {
          text: this.$t('fields.balance'),
          value: 'balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          accountFormat: true
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitSumTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/DEBIT%20SUM%20Template.xlsx`
    },
    tempDebitSumImportStatus() {
      if (!this.tempDebitSumImports || !this.tempDebitSumImports.length) {
        return null
      }
      return `${this.tempDebitSumImports.length} records will be imported`
    },
    debitSummaryBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitSummaryNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isShowDebitSum() {
      return this.formData.task_type === 'DCS'
    },
    isShowDebitDetail() {
      return this.formData.task_type === 'DCD'
    },
    isShowCombinedInOut() {
      return this.formData.task_type === 'CIO'
    },
    debitDetailHeaders() {
      return [
        {
          text: this.$t('fields.partnerCode'),
          value: 'partner_code'
        },
        {
          text: this.$t('fields.partnerName'),
          value: 'partner_name'
        },
        {
          text: this.$t('fields.partnerBranch'),
          value: 'partner_branch'
        },
        {
          text: this.$t('fields.docNo'),
          value: 'doc_no'
        },
        {
          text: this.$t('fields.docDate'),
          value: 'doc_date'
        },
        {
          text: this.$t('fields.docAmount'),
          value: 'doc_amount',
          accountFormat: true
        },
        {
          text: this.$t('fields.paidAmount'),
          value: 'paid_amount',
          accountFormat: true
        },
        {
          text: this.$t('fields.remaining'),
          value: 'remaining',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          accountFormat: true
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitDetailTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/DEBIT%20DETAIL%20Template.xlsx`
    },
    tempDebitDetailImportStatus() {
      if (!this.tempDebitDetailImports || !this.tempDebitDetailImports.length) {
        return null
      }
      return `${this.tempDebitDetailImports.length} records will be imported`
    },
    debitDetailBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitDetailNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    subsidiaryHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'account_code'
        },
        {
          text: this.$t('fields.date'),
          value: 'date'
        },
        {
          text: this.$t('fields.journal'),
          value: 'journal'
        },
        {
          text: this.$t('fields.docNo'),
          value: 'document_no'
        },
        {
          text: this.$t('fields.partnerCode'),
          value: 'partner_code'
        },
        {
          text: this.$t('fields.partnerName'),
          value: 'partner_name'
        },
        {
          text: this.$t('fields.partnerBranch'),
          value: 'partner_branch'
        },
        {
          text: this.$t('fields.detail'),
          value: 'detail'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.beginningBalance'),
          value: 'beginning_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'credit',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualBeginningBalance'),
          value: 'actual_beginning_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualDebit'),
          value: 'actual_debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualCredit'),
          value: 'actual_credit',
          accountFormat: true
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    tempSubsidiaryImportStatus() {
      if (!this.tempSubsidiaryImports || !this.tempSubsidiaryImports.length) {
        return null
      }
      return `${this.tempSubsidiaryImports.length} records will be imported`
    },
    subsidiaryTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/SUBSIDIARY%20ACCOUNT%20Template.xlsx`
    },
    subsidiaryBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    subsidiaryNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isShowSubsidiary() {
      return this.formData.task_type === 'SA'
    },
    isShowStockDetail() {
      return this.formData.task_type === 'STO'
    },
    stockDetailHeaders() {
      return [
        {
          text: this.$t('fields.productCode'),
          value: 'product_code'
        },
        {
          text: this.$t('fields.productName'),
          value: 'product_name'
        },
        {
          text: this.$t('fields.productBarcode'),
          value: 'product_barcode'
        },
        {
          text: this.$t('fields.productCategory'),
          value: 'product_category'
        },
        {
          text: this.$t('fields.productUnit'),
          value: 'product_unit'
        },
        {
          text: this.$t('fields.productCostPerUnit'),
          value: 'product_cost_per_unit',
          accountFormat: true
        },
        {
          text: this.$t('fields.productBalance'),
          value: 'product_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.productValue'),
          value: 'product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductUnit'),
          value: 'actual_product_unit'
        },
        {
          text: this.$t('fields.actualProductCostPerUnit'),
          value: 'actual_product_cost_per_unit',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductBalance'),
          value: 'actual_product_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductValue'),
          value: 'actual_product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.warehouse'),
          value: 'warehouse'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.bu'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    tempStockDetailImportStatus() {
      if (!this.tempStockDetailImports || !this.tempStockDetailImports.length) {
        return null
      }
      return `${this.tempStockDetailImports.length} records will be imported`
    },
    stockDetailTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/STOCK%20DETAIL%20OUTSTANDING%20Template.xlsx`
    },
    stockDetailBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    stockDetailNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.method'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    combinedInOutHeaders() {
      return [
        {
          text: this.$t('fields.productCode'),
          value: 'product_code'
        },
        {
          text: this.$t('fields.productName'),
          value: 'product_name'
        },
        {
          text: this.$t('fields.productBarcode'),
          value: 'product_barcode'
        },
        {
          text: this.$t('fields.productCategory'),
          value: 'product_category'
        },
        {
          text: this.$t('fields.productUnit'),
          value: 'product_unit'
        },
        {
          text: this.$t('fields.docNo'),
          value: 'doc_no'
        },
        {
          text: this.$t('fields.docDate'),
          value: 'doc_date'
        },
        {
          text: this.$t('fields.docDescription'),
          value: 'doc_description'
        },
        {
          text: this.$t('fields.vendorCode'),
          value: 'vendor_code'
        },
        {
          text: this.$t('fields.vendorName'),
          value: 'vendor_name'
        },
        {
          text: this.$t('fields.vendorBranch'),
          value: 'vendor_branch'
        },
        {
          text: this.$t('fields.productPrice'),
          value: 'product_price',
          accountFormat: true
        },
        {
          text: this.$t('fields.productQty'),
          value: 'product_qty',
          accountFormat: true
        },
        {
          text: this.$t('fields.productValue'),
          value: 'product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.initialValue'),
          value: 'initial_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductPrice'),
          value: 'actual_product_price',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductQty'),
          value: 'actual_product_qty',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductValue'),
          value: 'actual_product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualInitialValue'),
          value: 'actual_initial_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.warehouse'),
          value: 'warehouse'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    combinedInOutTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/COMBINED%20IN%20AND%20OUT%20Template.xlsx`
    },
    tempCombinedInOutImportStatus() {
      if (
        !this.tempCombinedInOutImports ||
        !this.tempCombinedInOutImports.length
      ) {
        return null
      }
      return `${this.tempCombinedInOutImports.length} records will be imported`
    },
    combinedInOutBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    combinedInOutNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isShowStockValue() {
      return this.formData.task_type === 'SVO'
    },
    stockValueHeaders() {
      return [
        {
          text: this.$t('fields.warehouse'),
          value: 'warehouse'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch.name'
        },
        {
          text: this.$t('fields.bu'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.productValue'),
          value: 'product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual_product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    tempStockValueImportStatus() {
      if (!this.tempStockValueImports || !this.tempStockValueImports.length) {
        return null
      }
      return `${this.tempStockValueImports.length} records will be imported`
    },
    stockValueTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/STOCK%20VALUE%20OUTSTANDING%20Template.xlsx`
    },
    stockValueBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    stockValueNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.method'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    manDayHeaders() {
      return [
        {
          text: this.$t('fields.position'),
          value: 'position.name'
        },
        {
          text: this.$t('fields.manDay'),
          value: 'man_day',
          hourFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    relatedCOAItemHeader() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail_id.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail_id.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.dr'),
          value: 'dr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.cr'),
          value: 'cr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.representAuditAmount'),
          value: 'respressent_audit_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.targetSamplingAmount'),
          value: 'target_sampling_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.samplingRespresent'),
          value: 'sampling_resoresebt',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.actualTargetSampling'),
          value: 'actual_target_sampling',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    sumRelatedCOAItemHeader() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail_id.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail_id.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.dr'),
          value: 'dr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.cr'),
          value: 'cr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.representAuditAmount'),
          value: 'total_respressent_audit_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.targetSamplingAmount'),
          value: 'total_target_sampling_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.samplingRespresent'),
          value: 'total_sampling_resoresebt',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'total_actual',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.actualTargetSampling'),
          value: 'total_actual_target_sampling',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.representLeft'),
          value: 'total_represent_left',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        }
      ]
    },
    subSumRelatedCOAItemHeader() {
      return [
        {
          text: this.$t('fields.task'),
          value: 'task.name',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.representAuditAmount'),
          value: 'respressent_audit_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.targetSamplingAmount'),
          value: 'target_sampling_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.samplingRespresent'),
          value: 'sampling_resoresebt',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.actualTargetSampling'),
          value: 'actual_target_sampling',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.representLeft'),
          value: 'represent_left',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  mounted() {
    if (this.$route.query.audit_plan_id) {
      this.getDataFromAuditPlanMaster()
      this.getMembers()
    }
    if (this.$route.query.task_template_header_id) {
      this.getDataFromTaskTemplate()
    }
    if (this.$route.query.task_type) {
      this.formData.task_type = this.$route.query.task_type
    }

    /* Force RefreshToken for Update User Info Before getUserLogin() */
    this.$store.dispatch('refreshToken').then(() => {
      this.getUserLogin()
      this.getParentTaskItem()
    })
  },
  created() {
    this.auditId = this.$route.query.audit_id || 0
  },
  methods: {
    accountingFormat: accounting,
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    stripHtml: stripHtml,
    formLoaded(value) {
      this.currentTaskTemplateHeader = value.task_template_header_id
    },
    groupLink(item) {
      return {
        name: 'groupLink',
        query: { group_link: item }
      }
    },
    getUserLogin() {
      const user_id = this.$store.getters.user.id
      this.$api({
        method: 'get',
        url: `contact/contact/?active=true&user_id=${user_id}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        if (data.results.length) {
          this.user_id = data.results[0].id
        }
      })
    },
    getParentTaskItem() {
      let url = ''
      if (
        this.$route.query.audit_plan_id !== null &&
        this.$route.query.audit_plan_id !== undefined
      ) {
        url =
          'task/task/?active=true&audit_plan_id=' +
          this.$route.query.audit_plan_id
      }
      this.parentTaskUrl = url

      this.formData.parent_task_id = this.$route.query.parent_task_id
      if (url && this.$route.query.parent_task_id) {
        this.$api({
          method: 'get',
          url: `task/task/${this.$route.query.parent_task_id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$set(this.formData, 'parent_task_id', data.detail)
          this.$refs.parent_task.setDefaultServerItems(data.detail)
        })
      }
    },
    getRequiredDocumentItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'document_template_id'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'document_template_id'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-required-document/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.requiredDocumentItemsLength = data.count
          this.requiredDocumentItems = data.results
          this.requiredDocumentClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getRequiredDocumentClientItems(multiSearch) {
      this.requiredDocumentClientItems = this.requiredDocumentItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    closeRequiredDocument() {
      this.editedItem = {}
      this.dialogRequiredDocument = false
    },
    saveRequiredDocument() {
      this.loading = true
      this.editedItem.task_id = this.$route.params.id

      if (this.editedItem.document_template_id) {
        this.editedItem.document_template_id =
          this.editedItem.document_template_id.id
      }

      if (this.editedItem.personal_document_id) {
        this.editedItem.personal_document_id =
          this.editedItem.personal_document_id.id
      }

      if (this.editedItem.contact_business_unit_id) {
        this.editedItem.contact_business_unit_id =
          this.editedItem.contact_business_unit_id.id
      }

      if (this.editedItem.contact_branch_id) {
        this.editedItem.contact_branch_id = this.editedItem.contact_branch_id.id
      }
      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `task/task-required-document/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeRequiredDocument()
      this.getRequiredDocumentItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogRequiredDocument = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-required-document/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    getTaskActivityProfileItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'activity_profile_id'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'activity_profile_id'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-activity-profile/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskActivityProfileItemsLength = data.count
          this.taskActivityProfileItems = data.results
          this.taskActivityProfileClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskActivityProfileClientItems(multiSearch) {
      this.taskActivityProfileClientItems =
        this.taskActivityProfileItems.filter((item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        })
    },
    closeTaskActivityProfile() {
      this.editedItem = {}
      this.dialogTaskActivityProfile = false
    },
    saveTaskActivityProfile() {
      this.loading = true
      this.editedItem.audit_plan_header_id = this.$route.params.audit_plan_id

      this.editedItem.activity_profile_id =
        this.editedItem.activity_profile_id.id
      this.editedItem.task_id = this.$route.params.id

      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `task/task-activity-profile/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTaskActivityProfile()
      this.getTaskActivityProfileItems()
    },
    onEditTaskActivityProfile(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTaskActivityProfile = true
    },
    onDeleteTaskActivityProfileItem(item) {
      this.onDeleteTaskActivityProfile(item)
    },
    onDeleteTaskActivityProfile(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-activity-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
        })
      })
    },
    getTaskRiskProfileItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'risk_profile_id'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'risk_profile_id'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-risk-profile/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskRiskProfileItemsLength = data.count
          this.taskRiskProfileItems = data.results
          this.taskRiskProfileClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskRiskProfileClientItems(multiSearch) {
      this.taskRiskProfileClientItems = this.taskRiskProfileItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    closeTaskRiskProfile() {
      this.editedItem = {}
      this.dialogTaskRiskProfile = false
    },
    saveTaskRiskProfile() {
      this.loading = true
      this.editedItem.audit_plan_header_id = this.$route.params.audit_plan_id

      this.editedItem.risk_profile_id = this.editedItem.risk_profile_id.id
      this.editedItem.task_id = this.$route.params.id

      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `task/task-risk-profile/${this.editId ? this.editId + '/' : ''}`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTaskRiskProfile()
      this.getTaskRiskProfileItems()
    },
    onEditTaskRiskProfile(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTaskRiskProfile = true
    },
    onDeleteTaskRiskProfileItem(item) {
      this.onDeleteTaskRiskProfile(item)
    },
    onDeleteTaskRiskProfile(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-risk-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
        })
      })
    },
    getTaskPropertyAndAgreementItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'property_and_agreement'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'property_and_agreement'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-property/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskPropertyAndAgreementItemsLength = data.count
          this.taskPropertyAndAgreementItems = data.results
          this.taskPropertyAndAgreementClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskPropertyAndAgreementClientItems(multiSearch) {
      this.taskPropertyAndAgreementClientItems =
        this.taskPropertyAndAgreementItems.filter((item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        })
    },
    closeTaskPropertyAndAgreement() {
      this.editedPropertyAndAgreementItem = {}
      this.dialogTaskPropertyAndAgreement = false
    },
    saveTaskPropertyAndAgreement() {
      this.loading = true
      this.editedPropertyAndAgreementItem.audit_plan_header_id =
        this.$route.params.audit_plan_id
      this.editedPropertyAndAgreementItem.task_id = this.$route.params.id

      if (this.editedPropertyAndAgreementItem.group_id) {
        this.editedPropertyAndAgreementItem.group_id =
          this.editedPropertyAndAgreementItem.group_name.id
      }

      if (this.editedPropertyAndAgreementItem.group_name) {
        this.editedPropertyAndAgreementItem.group_name =
          this.editedPropertyAndAgreementItem.group_name.name
      }

      if (this.editedPropertyAndAgreementItem.contact_branch_id) {
        this.editedPropertyAndAgreementItem.contact_branch_id =
          this.editedPropertyAndAgreementItem.contact_branch_id.id
      }

      if (this.editedPropertyAndAgreementItem.business_unit_id) {
        this.editedPropertyAndAgreementItem.business_unit_id =
          this.editedPropertyAndAgreementItem.business_unit_id.id
      }

      if (
        this.editedPropertyAndAgreementItem.group_link &&
        this.editedPropertyAndAgreementItem.group_id
      ) {
        this.editedPropertyAndAgreementItem.group_link =
          this.editedPropertyAndAgreementItem.group_link +
          this.editedPropertyAndAgreementItem.group_id
      }

      const data = this.editedPropertyAndAgreementItem

      this.$api({
        method: this.apiMethod,
        url: `task/task-property/${this.editId ? this.editId + '/' : ''}`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTaskPropertyAndAgreement()
      this.getTaskPropertyAndAgreementItems()
    },
    onEditTaskPropertyAndAgreement(item) {
      this.dialogTaskPropertyAndAgreement = true
      // this.editedPropertyAndAgreementItem = Object.assign({
      //   property_and_agreement: item.property_and_agreement,
      //   contact_branch_id: item.contact_branch_id,
      //   group_name: item.group_name,
      //   business_unit_id: item.business_unit_id,
      //   note: item.note
      // })
      this.editedPropertyAndAgreementItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
    },
    onDeleteTaskPropertyAndAgreementItem(item) {
      this.onDeleteTaskPropertyAndAgreement(item)
    },
    onDeleteTaskPropertyAndAgreement(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-property/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
        })
      })
    },
    getGroupName() {
      var url = ''
      var link = ''
      if (
        this.editedPropertyAndAgreementItem.property_and_agreement !== null &&
        this.editedPropertyAndAgreementItem.property_and_agreement !== undefined
      ) {
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement === 'asset'
        ) {
          url = 'asset/asset/'
          link = 'audit/property-and-agreement/asset/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'liability'
        ) {
          url = 'liability/liability'
          link = 'audit/property-and-agreement/liability/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'equity'
        ) {
          url = 'equity/equity'
          link = 'audit/property-and-agreement/equity/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'revenue'
        ) {
          url = 'revenue/revenue'
          link = 'audit/property-and-agreement/revenue/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'expense'
        ) {
          url = 'expense/expense'
          link = 'audit/property-and-agreement/expense/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'human_asset'
        ) {
          url = 'human-asset/human-asset'
          link = 'audit/property-and-agreement/human_asset/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'lawsuit'
        ) {
          url = 'lawsuit/lawsuit'
          link = 'audit/property-and-agreement/lawsuit/record/'
        }
      }
      this.groupNameUrl = url
      this.editedPropertyAndAgreementItem.group_link = link
    },
    getDataFromAuditPlanMaster() {
      const previous = this.$route.query.from
      if (['audit-plan-header', 'audit-header'].includes(previous)) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-header/${this.$route.query.audit_plan_id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.formData.contact_id = data.detail.contact_id
          this.formData.reference = data.detail.reference
          this.formData.audit_year = data.detail.audit_year
          this.formData.accounting_period_beginning =
            data.detail.accounting_period_beginning
          this.formData.accounting_period_ending =
            data.detail.accounting_period_ending
          this.formData.audit_period = data.detail.audit_period
          this.formData.deadline = data.detail.deadline
          this.formData.accounting_period = data.detail.accounting_period
          this.formData = Object.assign({}, this.formData)
          if (!this.$route.params.id) {
            this.$refs.contact.setDefaultServerItems(this.formData.contact_id)
          }
        })
      }
    },
    getDataFromTaskTemplate() {
      const task_template_header_id = this.$route.query.task_template_header_id
      this.$api({
        method: 'get',
        url: `task/task-template-header/${task_template_header_id}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        const task_template_header = data.detail
        this.formData.task_template_header_id = task_template_header
        // not working and broke task template header
        // this.formData.audit_year =
        //   task_template_header.audit_pland_id.audit_year
        // this.formData.accounting_period_beginning =
        //   task_template_header.audit_pland_id.accounting_period_beginning
        // this.formData.accounting_period_ending =
        //   task_template_header.audit_pland_id.accounting_period_ending
        this.$refs.task_template_header.setDefaultServerItems(
          this.formData.task_template_header_id
        )
      })
    },
    prepareData(data) {
      this.previousPage = this.$route.query.from
      data.task_template_header_id = data.task_template_header_id.id
      data.contact_id = data.contact_id.id

      if (
        this.formData.assign_to !== undefined &&
        this.formData.assign_to !== null
      ) {
        data.assign_to = data.assign_to.id
      }

      if (
        this.formData.parent_task_id !== undefined &&
        this.formData.parent_task_id !== null
      ) {
        data.parent_task_id = data.parent_task_id.id
      }

      if (this.formData.state === 'draft') {
        data.prepare_by = null
        data.review_by = null
      } else if (
        this.formData.state === 'sent_to_approve' &&
        this.formData.prepare_by === null
      ) {
        data.prepare_by = this.user_id
        data.prepare_date = new Date().toISOString().slice(0, 10)
      } else if (
        this.formData.state === 'approved' &&
        this.formData.review_by === null
      ) {
        data.prepare_by = data.prepare_by.id
        data.review_by = this.user_id
        data.review_date = new Date().toISOString().slice(0, 10)
      } else {
        data.prepare_by = data.prepare_by.id
        data.review_by = data.review_by.id
      }

      if (this.previousPage === 'audit-plan-header') {
        if (data.audit_plan_id !== undefined && data.audit_plan_id !== null) {
          data.audit_plan_id = data.audit_plan_id.id
        }
        if (
          this.$route.query.audit_plan_id !== undefined &&
          data.audit_plan_id !== null
        ) {
          data.audit_plan_id = this.$route.query.audit_plan_id
        }
      } else if (this.previousPage === 'audit-header') {
        data.audit_id = this.$route.query.audit_id
        data.audit_plan_id = this.$route.query.audit_plan_id
      }

      if (this.$route.query.task_debit_summary_id) {
        data.task_debit_summary_id = this.$route.query.task_debit_summary_id
      }
      if (this.$route.query.task_debit_detail_id) {
        data.task_debit_detail_id = this.$route.query.task_debit_detail_id
      }
      if (this.$route.query.task_subsidiary_id) {
        data.task_subsidiary_id = this.$route.query.task_subsidiary_id
      }
      if (this.$route.query.task_stock_detail_id) {
        data.task_stock_detail_id = this.$route.query.task_stock_detail_id
      }
      if (this.$route.query.task_stock_value_id) {
        data.task_stock_value_id = this.$route.query.task_stock_value_id
      }
      if (this.$route.query.task_combined_in_out_id) {
        data.task_combined_in_out_id = this.$route.query.task_combined_in_out_id
      }

      return data
    },
    async getSubtasks(options = null) {
      this.loading = true
      try {
        options.sortBy = ['sequence', 'reference']
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/subtask/?parent_task_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.subtasks = data.results
        this.subtasksCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getGrandChildSubtasks(item, value) {
      if (!value) return
      this.loading = true
      try {
        const options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['sequence', 'reference']
        }
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/subtask/?parent_task_id=${item.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.grandChildSubtasks[item.id] = data.results
        this.grandChildSubtasksLength[item.id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onNewTaskClick() {
      this.dialogNewTask = true
    },
    onCancelNewTaskClick() {
      this.newTaskTemplate = null
      this.dialogNewTask = false
    },
    onCreateNewTaskClick() {
      this.dialogNewTask = false
      this.$router.push({
        name: 'taskCreate',
        query: {
          from: this.$route.query.from,
          audit_plan_id: this.$route.query.audit_plan_id,
          audit_id: this.$route.query.audit_id,
          parent_task_id: this.$route.params.id
        }
      })
    },
    onSaveNewTaskClick() {
      this.dialogNewTask = false
      if (this.newTaskTemplate) {
        this.$router.push({
          name: 'taskCreate',
          query: {
            from: this.$route.query.from,
            audit_plan_id: this.$route.query.audit_plan_id,
            audit_id: this.$route.query.audit_id,
            parent_task_id: this.$route.params.id,
            task_template_header_id: this.newTaskTemplate.id
          }
        })
      }
    },
    onEditTask(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: {
          audit_plan_id: this.$route.query.audit_plan_id,
          audit_id: this.$route.query.audit_id,
          from: this.$route.query.from
        }
      })
    },
    onDeleteTask(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        })
          .then(() => {
            // this.selected = []
            this.$refs.subtaskTable.onServer()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async getConclusions(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/conclusion/`,
          params: params,
          hideSuccessAlert: true
        })
        this.conclusionItems = data.results
        this.conclusionItemsCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditConclusion(item) {
      this.editingConclusion = Object.assign({}, item)
      this.dialogConclusion = true
    },
    onCloseConclusion() {
      this.dialogConclusion = false
      this.$nextTick(() => {
        this.editingConclusion = {}
      })
      this.loading = false
    },
    async onSaveConclusion() {
      if (
        !this.editingConclusion.report_type ||
        !this.editingConclusion.conclusion
      ) {
        return
      }
      try {
        this.loading = true
        const data = {
          report_type: this.editingConclusion.report_type,
          conclusion: this.editingConclusion.conclusion
        }

        if (this.editingConclusion.id) {
          await this.$api({
            method: 'put',
            url: `task/task/${this.$route.params.id}/conclusion/${this.editingConclusion.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/conclusion/`,
            data
          })
        }
        this.onCloseConclusion()
        await this.getConclusions()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteConclusion(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/conclusion/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getConclusions()
        })
      })
      this.loading = false
    },
    async getObjectives(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/objective/`,
          params: params,
          hideSuccessAlert: true
        })
        this.objectiveItems = data.results
        this.objectiveItemsCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditObjective(item) {
      this.editingObjective = Object.assign({}, item)
      this.dialogObjective = true
    },
    onCloseObjective() {
      this.dialogObjective = false
      this.$nextTick(() => {
        this.editingObjective = {}
      })
      this.loading = false
    },
    async onSaveObjective() {
      if (
        !this.editingObjective.objective ||
        !this.editingObjective.chart_of_account_details
      ) {
        return
      }
      try {
        this.loading = true
        const data = {
          objective_id: this.editingObjective.objective.id,
          chart_of_account_detail_ids:
            this.editingObjective.chart_of_account_details.map(
              (value) => value.id
            )
        }

        if (this.editingObjective.id) {
          await this.$api({
            method: 'put',
            url: `task/task/${this.$route.params.id}/objective/${this.editingObjective.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/objective/`,
            data
          })
        }
        this.onCloseObjective()
        await this.getObjectives()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteObjective(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/objective/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getObjectives()
        })
      })
      this.loading = false
    },
    displayChartOfAccountDetails(value) {
      return value.map((v) => v.name).join(', ')
    },
    closeQuestionnaireDetail() {
      this.editedItem = {}
      this.dialogTaskQuestionnaire = false
      this.isNewQuestionnaireClicked = false
    },
    saveQuestionnaireDetail() {
      this.loading = true

      this.editedItem.audit_plan_header_id = this.$route.params.audit_plan_id
      this.editedItem.task_id = this.$route.params.id
      if (this.editedItem.questionnaire_header_id) {
        this.$api({
          method: 'get',
          url: 'questionnaire/questionnaire-detail/',
          params: {
            questionnaire_header_id: this.editedItem.questionnaire_header_id.id
          },
          hideSuccessAlert: true
        }).then(({ data }) => {
          data.results.forEach((item) => {
            this.editedItem.sequence = item.sequence
            this.editedItem.question = item.question
            this.editedItem.question_type = item.question_type
            this.editedItem.note = item.note
            this.editedItem.task_id = this.$route.params.id
            this.editedItem.lines = item.lines
            this.editedItem.active = true
            const data = this.editedItem
            this.$api({
              method: this.apiMethod,
              url: `task/task-questionnaire/${
                this.editId ? this.editId + '/' : ''
              }`,
              data
            })
          })

          this.loading = false
        })
      } else {
        this.editedItem.lines = this.editingQuestionnaire.lines.map((line) => ({
          choice: line.choice,
          points: line.points
        }))
        this.editedItem.active = true
        this.editedItem.task_id = this.$route.params.id
        const data = this.editedItem

        this.$api({
          method: this.apiMethod,
          url: `task/task-questionnaire/${
            this.editId ? this.editId + '/' : ''
          }`,
          data
        })
      }
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeQuestionnaireDetail()
      this.getTaskQuestionnaireItems()
    },
    getTaskQuestionnaireItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'sequence'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'sequence'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-questionnaire/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskQuestionnaireItemsLength = data.count
          this.taskQuestionnaireItems = data.results
          this.taskQuestionnaireClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskQuestionnaireClientItems(multiSearch) {
      this.taskQuestionnaireClientItems = this.taskQuestionnaireItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    onEditTaskQuestionnaire(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id

      this.editingQuestionnaire = Object.assign(
        {
          lines: []
        },
        item
      )

      this.dialogTaskQuestionnaire = true
    },
    onQuestionnaireNewItemClick(item) {
      this.editingQuestionnaire = Object.assign(
        {
          lines: []
        },
        item
      )
      this.isNewQuestionnaireClicked = true
    },
    onDeleteTaskQuestionnaireItem(item) {
      this.onDeleteTaskQuestionnaire(item)
    },
    onDeleteTaskQuestionnaire(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-questionnaire/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.taskQuestionnaireSelected = []
          this.getTaskQuestionnaireItems()
        })
      })
    },
    async getAdjustments() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        const { data } = await this.$api({
          method: 'get',
          url: `task/task-adjustment/?task_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjustments = data.results
        this.adjustmentsCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditAdjustment(item) {
      this.editingAdjustment = Object.assign(
        {
          lines: []
        },
        item
      )
      this.dialogAdjustment = true
    },
    onCloseAdjustment() {
      this.dialogAdjustment = false
      this.$nextTick(() => {
        this.editingAdjustment = {}
      })
      this.loading = false
    },
    onDeleteAdjustment(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/adjustment/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getAdjustments()
        })
      })
      this.loading = false
    },
    onCreateCustomerAudit(item) {
      this.loading = true
      return this.$api({
        method: 'post',
        url: `task/task/${this.$route.params.id}/adjustment/create_customer_audit/`,
        data: {
          id: item.id
        }
      }).then(() => {
        this.getAdjustments()
      })
    },
    async onSaveAdjustment() {
      if (!this.editingAdjustment.adj_type) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Please select Adjustment Type'
        })
        return
      }
      if (
        !this.editingAdjustment.lines ||
        !this.editingAdjustment.lines.length
      ) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Adjustment Lines must be filled'
        })
        return
      }

      if (!this.editingAdjustmentIsBalanced) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Unbalance Total Debit and Total Credit'
        })
        return
      }

      try {
        this.loading = true
        const data = {
          ref: this.editingAdjustment.ref,
          adj_type: this.editingAdjustment.adj_type,
          adj_note: this.editingAdjustment.adj_note,
          lines: this.editingAdjustment.lines.map((line) => ({
            chart_of_account_detail_id: line.chart_of_account_detail.id,
            debit: line.debit,
            credit: line.credit,
            note: line.note
          }))
        }

        if (this.editingAdjustment.id) {
          await this.$api({
            method: 'put',
            url: `task/task/${this.$route.params.id}/adjustment/${this.editingAdjustment.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/adjustment/`,
            data
          })
        }

        this.onCloseAdjustment()
        await this.getAdjustments()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditAdjustmentLine(line) {
      if (line) {
        this.editingAdjustmentLine = line
      } else {
        this.editingAdjustmentLine = Object.assign({
          debit: 0,
          credit: 0
        })
      }
      this.dialogAdjustmentLine = true
    },
    onCloseAdjustmentLine() {
      this.dialogAdjustmentLine = false
      this.$nextTick(() => {
        this.editingAdjustmentLine = {}
      })
    },
    onDeleteAdjustmentLine(items) {
      for (const item of items) {
        const i = this.editingAdjustment.lines.findIndex(
          (line) => line === item
        )
        if (i >= 0) {
          this.editingAdjustment.lines.splice(i, 1)
        }
      }
    },
    async onSaveAdjustmentLine() {
      if (!this.editingAdjustmentLine.chart_of_account_detail) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Please select Chart of Account'
        })
        return
      }
      if (
        this.editingAdjustmentLine.debit === null ||
        this.editingAdjustmentLine.credit === null
      ) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Debit or Credit must be filled'
        })
        return
      }
      if (
        this.editingAdjustmentLine.debit === 0 &&
        this.editingAdjustmentLine.credit === 0
      ) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Debit or Credit must be greater than 0'
        })
        return
      }
      const i = this.editingAdjustment.lines.findIndex(
        (line) => line === this.editingAdjustmentLine
      )
      if (i >= 0) {
        this.editingAdjustment.lines[i] = this.editingAdjustmentLine
      } else {
        this.editingAdjustment.lines.push(this.editingAdjustmentLine)
      }

      this.onCloseAdjustmentLine()
    },
    privateCalcAdjustmentTotalDebit(lines) {
      return _.sumBy(lines, (line) => parseFloat(line.debit))
    },
    privateCalcAdjustmentTotalCredit(lines) {
      return _.sumBy(lines, (line) => parseFloat(line.credit))
    },
    calcAdjustmentTotalDebit(adjustment) {
      if (!adjustment || !adjustment.lines || !adjustment.lines.length) {
        return 0
      }
      return this.privateCalcAdjustmentTotalDebit(adjustment.lines)
    },
    calcAdjustmentTotalCredit(adjustment) {
      if (!adjustment || !adjustment.lines || !adjustment.lines.length) {
        return 0
      }
      return this.privateCalcAdjustmentTotalCredit(adjustment.lines)
    },
    calcAdjustmentIsBalanced(adjustment) {
      return (
        this.calcAdjustmentTotalDebit(adjustment) ===
        this.calcAdjustmentTotalCredit(adjustment)
      )
    },
    async getAdjustmentLinesFromSubtasks(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/adjustment/line_from_subtask/`,
          hideSuccessAlert: true,
          params: params
        })
        this.adjustmentLinesFromSubtasks = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onTimeHrsKeyDow(event) {
      const specialKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'ArrowLeft',
        'ArrowRight'
      ]

      if (specialKeys.includes(event.key)) {
        return
      }

      const isDigit = event.key >= '0' && event.key <= '9'
      const isColon =
        event.key === ':' && event.target.value.indexOf(':') === -1

      if (!isDigit && !isColon) {
        event.preventDefault()
      }
    },
    onTimeHrsBlur(objName, field, value) {
      this.$set(this[objName], field, this.timeHrsAutoConvert(value))
    },
    timeHrsAutoConvert(value, return_int = false) {
      let _value = value

      if (_value || _value == 0) {
        _value = value.toString()
        if (_value.includes(':')) {
          const [hours, minutes] = _value.split(':').map(Number)
          _value = `${hours.toString().padStart(2, '0')}:${Math.min(minutes, 59)
            .toString()
            .padStart(2, '0')}`
        } else {
          const totalMinutes = parseInt(_value, 10)
          const hours = Math.floor(totalMinutes / 60)
            .toString()
            .padStart(2, '0')
          const minutes = (totalMinutes % 60).toString().padStart(2, '0')
          _value = `${hours}:${minutes}`
        }

        if (return_int) {
          if (_value.indexOf(':') > -1) {
            const [hours, minutes] = _value.split(':').map(Number)
            _value = hours * 60 + minutes
          } else {
            _value = 0
          }
        }
      }

      return _value
    },
    getMembers() {
      const auditParam = this.$route.query.audit_id
        ? `audit_header_id=${this.$route.query.audit_id}`
        : 'audit_header_id__isnull=true'
      this.$api({
        method: 'get',
        url: `audit/audit-plan-member/?audit_plan_header_id=${this.$route.query.audit_plan_id}&${auditParam}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        const dt = data.results.map((item) => ({
          value: item.id,
          label: item.contact_id.name
        }))
        this.memberTimesheetItems = dt
      })
    },
    async getTimesheetByPositions(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/timesheet-by-position/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByPositions = data.results
        this.timesheetByPositionsCount = data.count

        this.timesheetByPositionSumStandardManDay = data.sum_standard_man_day
        this.timesheetByPositionSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByPositionSumUnassigned = data.sum_unassigned
        this.timesheetByPositionSumAssignedProgress = data.sum_assigned_progress
        this.timesheetByPositionSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByPositionSumActualManDay = data.sum_actual_man_day
        this.timesheetByPositionSumVariance = data.sum_variance
        this.timesheetByPositionSumTaskPerformance = data.sum_task_performance
        this.timesheetByPositionSumWorkProgress = data.sum_work_progress
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMembers(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/timesheet-by-member/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByMembers = data.results
        this.timesheetByMembersCount = data.count
        this.timesheetByMemberSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByMemberSumWeight = data.sum_weight
        this.timesheetByMemberSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByMemberSumActualManDay = data.sum_actual_man_day
        this.timesheetByMemberSumVariance = data.sum_variance
        this.timesheetByMemberSumTaskPerformance = data.sum_task_performance
        this.timesheetByMemberSumWorkProgress = data.sum_work_progress
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMemberIdItems(item, value, options) {
      if (!value || !options) return
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/timesheet-by-member/${item.member_id}/`,
          params: params,
          hideSuccessAlert: true
        })
        this.timesheetByMemberIdItems[item.member_id] = data.results
        this.timesheetByMemberIdItemsCount[item.member_id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetItemItems(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/timesheet/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetItemItems = data.results.map((item) => ({
          ...item,
          expected_man_day_converted: this.timeHrsAutoConvert(
            item.expected_man_day
          ),
          actual_man_day_converted: this.timeHrsAutoConvert(item.actual_man_day)
        }))
        this.timesheetItemServerItemsLength = data.count
        this.timesheetSumAssignedManDay = data.sum_assigned_man_day
        this.timesheetSumActualManDay = data.sum_actual_man_day
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteTimesheetItemItem(item) {
      this.onDeleteTimesheetItem(item)
    },
    onDeleteTimesheetItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/timesheet`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getTimesheetItemItems()
        })
      })
      this.loading = false
    },
    closeTimesheetItem() {
      this.dialogTimesheetItem = false
      this.$nextTick(() => {
        this.editedTimesheetItemItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.editId = null
      this.apiMethod = 'post'
      this.loading = false
    },
    async saveTimesheetItem() {
      this.loading = true

      const data = {}
      data.audit_plan_header_id = this.$route.query.audit_plan_id

      if (
        this.editedTimesheetItemItem.audit_plan_member !== null &&
        this.editedTimesheetItemItem.audit_plan_member !== undefined
      ) {
        data.audit_plan_member_id =
          this.editedTimesheetItemItem.audit_plan_member
      }
      if (this.editedTimesheetItemItem.assigned_by) {
        data.assigned_by_id = this.editedTimesheetItemItem.assigned_by
      }

      data.expected_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.expected_man_day,
        true
      )
      data.actual_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.actual_man_day,
        true
      )
      data.date = this.editedTimesheetItemItem.date
      data.actual_start_date = this.editedTimesheetItemItem.actual_start_date
      if (this.editedTimesheetItemItem.task_standard_man_day) {
        data.task_standard_man_day_id =
          this.editedTimesheetItemItem.task_standard_man_day.id
      }
      data.note = this.editedTimesheetItemItem.note

      await this.$api({
        method: this.apiMethod,
        url: `audit/task/${this.$route.params.id}/timesheet/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })
      this.loading = false
      this.closeTimesheetItem()
      this.getTimesheetItemItems()
    },
    onEditTimesheetItem(item) {
      this.editedTimesheetItemItem = Object.assign({}, item)
      if (item.audit_plan_member) {
        this.editedTimesheetItemItem.audit_plan_member =
          item.audit_plan_member.id
      }
      if (item.assigned_by) {
        this.editedTimesheetItemItem.assigned_by = item.assigned_by.id
      }
      this.editedTimesheetItemItem.task_standard_man_day =
        item.task_standard_man_day
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTimesheetItem = true
      this.editedTimesheetItemItem.expected_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.expected_man_day
      )
      this.editedTimesheetItemItem.actual_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.actual_man_day
      )
      if (
        this.editedTimesheetItemItem.task_standard_man_day &&
        this.$refs.timesheet_task_standard_man_day
      ) {
        this.$refs.timesheet_task_standard_man_day.setDefaultServerItems(
          item.task_standard_man_day
        )
      }
      // if (
      //   this.editedTimesheetItemItem.audit_plan_member &&
      //   this.$refs.timesheet_audit_plan_member
      // ) {
      //   const opt = this.memberTimesheetItems.find(
      //     (opt) => opt.value === item.audit_plan_member.id
      //   )
      //   if (opt) {
      //     this.$refs.timesheet_audit_plan_member.setDefaultServerItems(opt)
      //   }
      // }
      // if (
      //   this.editedTimesheetItemItem.assigned_by &&
      //   this.$refs.timesheet_assigned_by
      // ) {
      //   const opt = this.memberTimesheetItems.find(
      //     (opt) => opt.value === item.audit_plan_member.id
      //   )
      //   if (opt) {
      //     this.$refs.timesheet_assigned_by.setDefaultServerItems(opt)
      //   }
      // }
    },
    async getDebitSummaries(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-summary/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitSummaries = data.results
        this.debitSummariesCount = data.count
        this.debitSummariesSumBalance = data.sum_balance ?? 0
        this.debitSummariesSumActual = data.sum_actual ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditDebitSummary(item) {
      this.editingDebitSum = Object.assign({}, item)
      this.dialogDebitSum = true
    },
    onCloseDebitSummary() {
      this.dialogDebitSum = false
      this.$nextTick(() => {
        this.editingDebitSum = {}
      })
      this.loading = false
    },
    onDeleteDebitSummary(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-summary/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.debitSummarySelected = []
          this.getDebitSummaries()
        })
      })
      this.loading = false
    },
    async onSaveDebitSummary() {
      try {
        this.loading = true
        const data = {
          partner_code: this.editingDebitSum.partner_code,
          partner_name: this.editingDebitSum.partner_name,
          partner_branch: this.editingDebitSum.partner_branch,
          partner_address: this.editingDebitSum.partner_address,
          partner_attn_person: this.editingDebitSum.partner_attn_person,
          balance: this.editingDebitSum.balance,
          note: this.editingDebitSum.note,
          branch: this.editingDebitSum.branch,
          business_unit:
            this.editingDebitSum.business_unit?.id ??
            this.editingDebitSum.business_unit,
          actual: this.editingDebitSum.actual,
          status: this.editingDebitSum.status,
          sampling_type: this.editingDebitSum.sampling_type,
          dummy: this.editingDebitSum.dummy
        }

        if (this.editingDebitSum.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-summary/${this.editingDebitSum.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/`,
            data
          })
        }

        this.onCloseDebitSummary()
        await this.getDebitSummaries()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onImportDebitSummary() {
      this.debitSumImportForm = {}
      this.dialogDebitSumImport = true
    },
    onCloseImportDebitSummary() {
      this.debitSumImportForm = {}
      this.dialogDebitSumImport = false
    },
    onDebitSumImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempDebitSumImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 12) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 12. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempDebitSumImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  partner_address: row[3],
                  partner_attn_person: row[4],
                  balance: row[5]?.trim().replace(/[,-]/g, ''),
                  note: row[6],
                  branch: row[7],
                  business_unit: row[8],
                  actual: row[9].trim().replace(/[,-]/g, ''),
                  status: row[10].trim(),
                  sampling_type: null,
                  dummy: row[11]
                })
              }
            }
          })
        } else {
          this.tempDebitSumImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 12) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 12. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempDebitSumImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  partner_address: row[3],
                  partner_attn_person: row[4],
                  balance: row[5],
                  note: row[6],
                  branch: row[7],
                  business_unit: row[8],
                  actual: row[9],
                  status: row[10],
                  sampling_type: null,
                  dummy: row[11]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportDebitSummaryDo() {
      try {
        this.loading = true
        const data = this.tempDebitSumImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/debit-summary/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportDebitSummary()
        await this.getDebitSummaries()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    async getDebitSumBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-summary-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitSummaryBiases = data.results
        this.debitSummaryBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDebitSumBiasClick() {
      this.dialogDebitSumBias = true
    },
    onEditDebitSumBias(item) {
      this.editingDebitSumBias = Object.assign({}, item)
      this.dialogEditDebitSumBias = true
    },
    async onDeleteDebitSumBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-summary-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitSumBiases()
        })
      })
      this.loading = false
    },
    onDebitSumBiasClose() {
      this.dialogDebitSumBias = false
    },
    async onDebitSumBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Summary. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/compute_bias/`,
            data: {}
          })
          this.onDebitSumBiasClose()
          await this.getDebitSummaries()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitSumBiasClose() {
      this.dialogEditDebitSumBias = false
    },
    async onEditDebitSumBiasSave() {
      try {
        this.loading = true
        const data = {
          field: this.editingDebitSumBias.field,
          operator: this.editingDebitSumBias.operator,
          value1: this.editingDebitSumBias.value1,
          reason: this.editingDebitSumBias.reason
        }
        if (
          this.editingDebitSumBias.operator === 'Between' ||
          this.editingDebitSumBias.operator === 'Not Between'
        ) {
          data.value2 = this.editingDebitSumBias.value2
        }

        if (this.editingDebitSumBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-summary-bias/${this.editingDebitSumBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary-bias/`,
            data
          })
        }

        this.onEditDebitSumBiasClose()
        await this.getDebitSumBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onResetSumBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Summary. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/reset/`,
            data: {}
          })
          await this.getDebitSummaries()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async getDebitSumNonBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitSummaryNonBiases = data.results
        this.debitSummaryNonBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDebitSumNonBiasClick() {
      this.dialogDebitSumNonBias = true
    },
    onEditDebitSumNonBias(item) {
      this.editingDebitSumNonBias = Object.assign({}, item)
      this.dialogEditDebitSumNonBias = true
    },
    async onDeleteDebitSumNonBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitSumNonBiases()
        })
      })
      this.loading = false
    },
    onDebitSumNonBiasClose() {
      this.dialogDebitSumNonBias = false
    },
    async onDebitSumNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Debit Summary and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/compute_non_bias/`,
            data: {}
          })
          this.onDebitSumNonBiasClose()
          await this.getDebitSummaries()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitSumNonBiasClose() {
      this.dialogEditDebitSumNonBias = false
    },
    async onEditDebitSumNonBiasSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editingDebitSumNonBias.sampling_size,
          sampling_method: this.editingDebitSumNonBias.sampling_method
        }

        if (this.editingDebitSumNonBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/${this.editingDebitSumNonBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/`,
            data
          })
        }

        this.onEditDebitSumNonBiasClose()
        await this.getDebitSumNonBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async getDebitDetails(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-detail/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitDetails = data.results
        this.debitDetailsCount = data.count
        this.debitDetailsSumDocAmount = data.sum_doc_amount ?? 0
        this.debitDetailsSumPaidAmount = data.sum_paid_amount ?? 0
        this.debitDetailsSumRemaining = data.sum_remaining ?? 0
        this.debitDetailsSumActual = data.sum_actual ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditDebitDetail(item) {
      this.editingDebitDetail = Object.assign({}, item)
      this.dialogDebitDetail = true
    },
    onCloseDebitDetail() {
      this.dialogDebitDetail = false
      this.$nextTick(() => {
        this.editingDebitDetail = {}
      })
      this.loading = false
    },
    onDeleteDebitDetail(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-detail/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.debitDetailSelected = []
          this.getDebitDetails()
        })
      })
      this.loading = false
    },
    async onSaveDebitDetail() {
      try {
        this.loading = true
        const data = {
          partner_code: this.editingDebitDetail.partner_code,
          partner_name: this.editingDebitDetail.partner_name,
          partner_branch: this.editingDebitDetail.partner_branch,
          doc_no: this.editingDebitDetail.doc_no,
          doc_date: this.editingDebitDetail.doc_date,
          doc_amount: this.editingDebitDetail.doc_amount,
          paid_amount: this.editingDebitDetail.paid_amount,
          remaining: this.editingDebitDetail.remaining,
          note: this.editingDebitDetail.note,
          branch: this.editingDebitDetail.branch,
          business_unit:
            this.editingDebitDetail.business_unit?.id ??
            this.editingDebitDetail.business_unit,
          actual: this.editingDebitDetail.actual,
          status: this.editingDebitDetail.status,
          sampling_type: this.editingDebitDetail.sampling_type,
          dummy: this.editingDebitDetail.dummy
        }

        if (this.editingDebitDetail.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-detail/${this.editingDebitDetail.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/`,
            data
          })
        }

        this.onCloseDebitDetail()
        await this.getDebitDetails()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    onImportDebitDetail() {
      this.debitSumImportForm = {}
      this.dialogDebitDetailImport = true
    },
    onCloseImportDebitDetail() {
      this.debitSumImportForm = {}
      this.dialogDebitDetailImport = false
    },
    onDebitDetailImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempDebitDetailImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 14) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempDebitDetailImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  doc_no: row[3],
                  doc_date: DateTime.fromFormat(row[4], 'dd/LL/yyyy').toFormat(
                    'yyyy-LL-dd'
                  ),
                  doc_amount: row[5]?.trim().replace(/[,-]/g, ''),
                  paid_amount: row[6]?.trim().replace(/[,-]/g, ''),
                  remaining: row[7]?.trim().replace(/[,-]/g, ''),
                  note: row[8],
                  branch: row[9],
                  business_unit: row[10],
                  actual: row[11].trim().replace(/[,-]/g, ''),
                  status: row[12].trim(),
                  sampling_type: null,
                  dummy: row[13]
                })
              }
            }
          })
        } else {
          this.tempDebitDetailImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 14) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempDebitDetailImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  doc_no: row[3],
                  doc_date: row[4]
                    ? DateTime.fromJSDate(row[4]).toFormat('yyyy-LL-dd')
                    : null,
                  doc_amount: row[5],
                  paid_amount: row[6],
                  remaining: row[7],
                  note: row[8],
                  branch: row[9],
                  business_unit: row[10],
                  actual: row[11],
                  status: row[12],
                  sampling_type: null,
                  dummy: row[13]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportDebitDetailDo() {
      try {
        this.loading = true
        const data = this.tempDebitDetailImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/debit-detail/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportDebitDetail()
        await this.getDebitDetails()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },

    async getDebitDetailBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-detail-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitDetailBiases = data.results
        this.debitDetailBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDebitDetailBiasClick() {
      this.dialogDebitDetailBias = true
    },
    onEditDebitDetailBias(item) {
      this.editingDebitDetailBias = Object.assign({}, item)
      this.dialogEditDebitDetailBias = true
    },
    async onDeleteDebitDetailBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-detail-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitDetailBiases()
        })
      })
      this.loading = false
    },
    onDebitDetailBiasClose() {
      this.dialogDebitDetailBias = false
    },
    async onDebitDetailBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Detail. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/compute_bias/`,
            data: {}
          })
          this.onDebitDetailBiasClose()
          await this.getDebitDetails()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitDetailBiasClose() {
      this.dialogEditDebitDetailBias = false
    },
    async onEditDebitDetailBiasSave() {
      try {
        this.loading = true
        const data = {
          field: this.editingDebitDetailBias.field,
          operator: this.editingDebitDetailBias.operator,
          value1: this.editingDebitDetailBias.value1,
          reason: this.editingDebitDetailBias.reason
        }
        if (
          this.editingDebitDetailBias.operator === 'Between' ||
          this.editingDebitDetailBias.operator === 'Not Between'
        ) {
          data.value2 = this.editingDebitDetailBias.value2
        }

        if (this.editingDebitDetailBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-detail-bias/${this.editingDebitDetailBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail-bias/`,
            data
          })
        }

        this.onEditDebitDetailBiasClose()
        await this.getDebitDetailBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onResetDetailBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Detail. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/reset/`,
            data: {}
          })
          await this.getDebitDetails()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    async getDebitDetailNonBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitDetailNonBiases = data.results
        this.debitDetailNonBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDebitDetailNonBiasClick() {
      this.dialogDebitDetailNonBias = true
    },
    onEditDebitDetailNonBias(item) {
      this.editingDebitDetailNonBias = Object.assign({}, item)
      this.dialogEditDebitDetailNonBias = true
    },
    async onDeleteDebitDetailNonBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitDetailNonBiases()
        })
      })
      this.loading = false
    },
    onDebitDetailNonBiasClose() {
      this.dialogDebitDetailNonBias = false
    },
    async onDebitDetailNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Debit Detail and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/compute_non_bias/`,
            data: {}
          })
          this.onDebitDetailNonBiasClose()
          await this.getDebitDetails()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitDetailNonBiasClose() {
      this.dialogEditDebitDetailNonBias = false
    },
    async onEditDebitDetailNonBiasSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editingDebitDetailNonBias.sampling_size,
          sampling_method: this.editingDebitDetailNonBias.sampling_method
        }

        if (this.editingDebitDetailNonBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/${this.editingDebitDetailNonBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/`,
            data
          })
        }

        this.onEditDebitDetailNonBiasClose()
        await this.getDebitDetailNonBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onSumDetailFromDetailClick() {
      console.log(this.editingDebitSum.subtask)
      if (!this.editingDebitSum.subtask) {
        return
      }
      try {
        this.loading = true

        const response = await this.$api({
          method: 'get',
          url: `audit/task/${this.editingDebitSum.subtask.id}/debit-detail/`,
          hideSuccessAlert: true
        })
        console.log(response)

        this.editingDebitSum.actual =
          response.data.sum_actual ?? this.editingDebitSum.actual
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async getSubsidiary(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/subsidiary-account/`,
          params: params,
          hideSuccessAlert: true
        })
        this.subsidiaryItemItems = data.results
        this.subsidiaryItemServerItemsLength = data.count
        this.subsidiarySumDebit = data.sum_debit ?? 0
        this.subsidiarySumCredit = data.sum_credit ?? 0
        this.subsidiarySumActualDebit = data.sum_actual_debit ?? 0
        this.subsidiarySumActualCredit = data.sum_actual_credit ?? 0
        this.subsidiarySumBeginningBalance = data.sum_beginning_balance ?? 0
        this.subsidiarySumActualBeginningBalance =
          data.sum_actual_beginning_balance ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditSubsidiary(item) {
      this.editedSubsidiary = Object.assign({}, item)
      this.dialogSubsidiary = true
    },
    onCloseSubsidiary() {
      this.dialogSubsidiary = false
      this.$nextTick(() => {
        this.editedSubsidiary = {}
      })
      this.loading = false
    },
    onDeleteSubsidiary(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/subsidiary-account/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.subsidiarySelected = []
          this.getSubsidiary()
        })
      })
      this.loading = false
    },
    async onSaveSubsidiary() {
      try {
        this.loading = true
        const data = {
          account_code: this.editedSubsidiary.account_code.code,
          date: this.editedSubsidiary.date,
          journal: this.editedSubsidiary.journal,
          document_no: this.editedSubsidiary.document_no,
          partner_code: this.editedSubsidiary.partner_code,
          partner_name: this.editedSubsidiary.partner_name,
          partner_branch: this.editedSubsidiary.partner_branch,
          detail: this.editedSubsidiary.detail,
          note: this.editedSubsidiary.note,
          beginning_balance: this.editedSubsidiary.beginning_balance,
          debit: this.editedSubsidiary.debit,
          credit: this.editedSubsidiary.credit,
          actual_beginning_balance:
            this.editedSubsidiary.actual_beginning_balance,
          actual_debit: this.editedSubsidiary.actual_debit,
          actual_credit: this.editedSubsidiary.actual_credit,
          business_unit:
            this.editedSubsidiary.business_unit?.id ??
            this.editedSubsidiary.business_unit,
          branch: this.editedSubsidiary.branch,
          status: this.editedSubsidiary.status,
          dummy: this.editedSubsidiary.dummy
        }

        if (this.editedSubsidiary.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/${this.editedSubsidiary.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/`,
            data
          })
        }
        this.onCloseSubsidiary()
        await this.getSubsidiary()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    onImportSubsidiary() {
      this.subsidiaryImportForm = {}
      this.dialogSubsidiaryImport = true
    },
    onSubsidiaryImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempSubsidiaryImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 19) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempSubsidiaryImports.push({
                  account_code: row[0],
                  date: DateTime.fromFormat(row[1], 'dd/LL/yyyy').toFormat(
                    'yyyy-LL-dd'
                  ),
                  journal: row[2],
                  document_no: row[3],
                  partner_code: row[4],
                  partner_name: row[5],
                  partner_branch: row[6],
                  detail: row[7],
                  note: row[8],
                  beginning_balance: row[9]?.trim().replace(/[,-]/g, ''),
                  debit: row[10]?.trim().replace(/[,-]/g, ''),
                  credit: row[11]?.trim().replace(/[,-]/g, ''),
                  actual_beginning_balance: row[12]
                    ?.trim()
                    .replace(/[,-]/g, ''),
                  actual_debit: row[13]?.trim().replace(/[,-]/g, ''),
                  actual_credit: row[14]?.trim().replace(/[,-]/g, ''),
                  business_unit: row[15],
                  branch: row[16],
                  status: row[17].trim(),
                  sampling_type: null,
                  dummy: row[18]
                })
              }
            }
          })
        } else {
          this.tempSubsidiaryImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 19) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempSubsidiaryImports.push({
                  account_code: row[0],
                  date: row[1]
                    ? DateTime.fromJSDate(row[1]).toFormat('yyyy-LL-dd')
                    : null,
                  journal: row[2],
                  document_no: row[3],
                  partner_code: row[4],
                  partner_name: row[5],
                  partner_branch: row[6],
                  detail: row[7],
                  note: row[8],
                  beginning_balance: row[9],
                  debit: row[10],
                  credit: row[11],
                  actual_beginning_balance: row[12],
                  actual_debit: row[13],
                  actual_credit: row[14],
                  business_unit: row[15],
                  branch: row[16],
                  status: row[17],
                  sampling_type: null,
                  dummy: row[18]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportSubsidiaryDo() {
      try {
        this.loading = true
        const data = this.tempSubsidiaryImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/subsidiary-account/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportSubsidiary()
        await this.getSubsidiary()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onCloseImportSubsidiary() {
      this.subsidiaryImportForm = {}
      this.dialogSubsidiaryImport = false
    },

    onSubsidiaryBiasClick() {
      this.dialogSubsidiaryBias = true
    },
    async getSubsidiaryBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.subsidiaryBiasItemItems = data.results
        this.subsidiaryBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditSubsidiaryBiasCondition(item) {
      this.editedSubsidiaryBiasCondition = Object.assign({}, item)
      this.dialogSubsidiaryBiasCondition = true
    },
    async onDeleteSubsidiaryBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getSubsidiaryBiasItems()
        })
      })
      this.loading = false
    },
    onSubsidiaryBiasConditionClose() {
      this.dialogSubsidiaryBiasCondition = false
    },
    async onSubsidiaryBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          field: this.editedSubsidiaryBiasCondition.field,
          operator: this.editedSubsidiaryBiasCondition.operator,
          value1: this.editedSubsidiaryBiasCondition.value1,
          reason: this.editedSubsidiaryBiasCondition.reason
        }
        if (
          this.editedSubsidiaryBiasCondition.operator === 'Between' ||
          this.editedSubsidiaryBiasCondition.operator === 'Not Between'
        ) {
          data.value2 = this.editedSubsidiaryBiasCondition.value2
        }
        if (this.editedSubsidiaryBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/${this.editedSubsidiaryBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/`,
            data
          })
        }
        this.onSubsidiaryBiasConditionClose()
        await this.getSubsidiaryBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onSubsidiaryBiasClose() {
      this.dialogSubsidiaryBias = false
    },
    async onSubsidiaryBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Subsidiary Account. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/compute_bias/`,
            data: {}
          })
          this.onSubsidiaryBiasClose()
          await this.getSubsidiary()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    onSubsidiaryNonBiasClick() {
      this.dialogSubsidiaryNonBias = true
    },
    async getSubsidiaryNonBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.subsidiaryNonBiasItemItems = data.results
        this.subsidiaryNonBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditSubsidiaryNonBiasCondition(item) {
      this.editedSubsidiaryNonBiasCondition = Object.assign({}, item)
      this.dialogSubsidiaryNonBiasCondition = true
    },
    async onDeleteSubsidiaryNonBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getSubsidiaryNonBiasItems()
        })
      })
      this.loading = false
    },
    onSubsidiaryNonBiasConditionClose() {
      this.dialogSubsidiaryNonBiasCondition = false
    },
    async onSubsidiaryNonBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editedSubsidiaryNonBiasCondition.sampling_size,
          sampling_method: this.editedSubsidiaryNonBiasCondition.sampling_method
        }

        if (this.editedSubsidiaryNonBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/${this.editedSubsidiaryNonBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/`,
            data
          })
        }

        this.onSubsidiaryNonBiasConditionClose()
        await this.getSubsidiaryNonBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onSubsidiaryNonBiasClose() {
      this.dialogSubsidiaryNonBias = false
    },
    async onSubsidiaryNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Subsidiary Account and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/compute_non_bias/`,
            data: {}
          })
          this.onSubsidiaryNonBiasClose()
          await this.getSubsidiary()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    async onResetSubsidiaryBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Subsidiary Account. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/reset/`,
            data: {}
          })
          await this.getSubsidiary()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    async getStockDetail(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockDetailItemItems = data.results
        this.stockDetailItemServerItemsLength = data.count
        this.stockDetailSumProductBalance = data.sum_product_balance ?? 0
        this.stockDetailSumProductValue = data.sum_product_value ?? 0
        this.stockDetailSumActualProductBalance =
          data.sum_actual_product_balance ?? 0
        this.stockDetailSumActualProductValue =
          data.sum_actual_product_value ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockDetail(item) {
      this.editedStockDetail = Object.assign({}, item)
      this.dialogStockDetail = true
    },
    onCloseStockDetail() {
      this.dialogStockDetail = false
      this.$nextTick(() => {
        this.editedStockDetail = {}
      })
      this.loading = false
    },
    onDeleteStockDetail(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.stockDetailSelected = []
          this.getStockDetail()
        })
      })
      this.loading = false
    },
    async onSaveStockDetail() {
      try {
        this.loading = true
        const data = {
          product_code: this.editedStockDetail.product_code,
          product_name: this.editedStockDetail.product_name,
          product_barcode: this.editedStockDetail.product_barcode,
          product_category: this.editedStockDetail.product_category,
          product_unit: this.editedStockDetail.product_unit,
          product_cost_per_unit: this.editedStockDetail.product_cost_per_unit,
          product_balance: this.editedStockDetail.product_balance,
          product_value: this.editedStockDetail.product_value,
          actual_product_unit: this.editedStockDetail.actual_product_unit,
          actual_product_cost_per_unit:
            this.editedStockDetail.actual_product_cost_per_unit,
          actual_product_balance: this.editedStockDetail.actual_product_balance,
          actual_product_value: this.editedStockDetail.actual_product_value,
          warehouse: this.editedStockDetail.warehouse,
          branch: this.editedStockDetail.branch,
          business_unit:
            this.editedStockDetail.business_unit?.id ??
            this.editedStockDetail.business_unit,
          note: this.editedStockDetail.note,
          status: this.editedStockDetail.status,
          dummy: this.editedStockDetail.dummy
        }

        if (this.editedStockDetail.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/${this.editedStockDetail.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/`,
            data
          })
        }
        this.onCloseStockDetail()
        await this.getStockDetail()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    onImportStockDetail() {
      this.stockDetailImportForm = {}
      this.dialogStockDetailImport = true
    },
    onStockDetailImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempStockDetailImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 18) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 18. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempStockDetailImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  product_cost: row[5]?.trim().replace(/[,-]/g, ''),
                  product_balance: row[6]?.trim().replace(/[,-]/g, ''),
                  product_value: row[7]?.trim().replace(/[,-]/g, ''),
                  actual_product_unit: row[8],
                  actual_product_cost: row[9]?.trim().replace(/[,-]/g, ''),
                  actual_product_balance: row[10]?.trim().replace(/[,-]/g, ''),
                  actual_product_value: row[11]?.trim().replace(/[,-]/g, ''),
                  warehouse: row[12],
                  branch: row[13],
                  business_unit: row[14],
                  note: row[15],
                  status: row[16].trim(),
                  sampling_type: null,
                  dummy: row[17]
                })
              }
            }
          })
        } else {
          this.tempStockDetailImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 18) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 18. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempStockDetailImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  product_cost: row[5],
                  product_balance: row[6],
                  product_value: row[7],
                  actual_product_unit: row[8],
                  actual_product_cost: row[9],
                  actual_product_balance: row[10],
                  actual_product_value: row[11],
                  warehouse: row[12],
                  branch: row[13],
                  business_unit: row[14],
                  note: row[15],
                  status: row[16],
                  sampling_type: null,
                  dummy: row[17]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportStockDetailDo() {
      try {
        this.loading = true
        const data = this.tempStockDetailImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportStockDetail()
        await this.getStockDetail()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onCloseImportStockDetail() {
      this.stockDetailImportForm = {}
      this.dialogStockDetailImport = false
    },

    onStockDetailBiasClick() {
      this.dialogStockDetailBias = true
    },
    async getStockDetailBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockDetailBiasItemItems = data.results
        this.stockDetailBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockDetailBiasCondition(item) {
      this.editedStockDetailBiasCondition = Object.assign({}, item)
      this.dialogStockDetailBiasCondition = true
    },
    async onDeleteStockDetailBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockDetailBiasItems()
        })
      })
      this.loading = false
    },
    onStockDetailBiasConditionClose() {
      this.dialogStockDetailBiasCondition = false
    },
    async onStockDetailBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          field: this.editedStockDetailBiasCondition.field,
          operator: this.editedStockDetailBiasCondition.operator,
          value1: this.editedStockDetailBiasCondition.value1,
          reason: this.editedStockDetailBiasCondition.reason
        }
        if (
          this.editedStockDetailBiasCondition.operator === 'Between' ||
          this.editedStockDetailBiasCondition.operator === 'Not Between'
        ) {
          data.value2 = this.editedStockDetailBiasCondition.value2
        }
        if (this.editedStockDetailBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/${this.editedStockDetailBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/`,
            data
          })
        }
        this.onStockDetailBiasConditionClose()
        await this.getStockDetailBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockDetailBiasClose() {
      this.dialogStockDetailBias = false
    },
    async onStockDetailBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Detail Outstanding. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/compute_bias/`,
            data: {}
          })
          this.onStockDetailBiasClose()
          await this.getStockDetail()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    onStockDetailNonBiasClick() {
      this.dialogStockDetailNonBias = true
    },
    async getStockDetailNonBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockDetailNonBiasItemItems = data.results
        this.stockDetailNonBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockDetailNonBiasCondition(item) {
      this.editedStockDetailNonBiasCondition = Object.assign({}, item)
      this.dialogStockDetailNonBiasCondition = true
    },
    async onDeleteStockDetailNonBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockDetailNonBiasItems()
        })
      })
      this.loading = false
    },
    onStockDetailNonBiasConditionClose() {
      this.dialogStockDetailNonBiasCondition = false
    },
    async onStockDetailNonBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editedStockDetailNonBiasCondition.sampling_size,
          sampling_method:
            this.editedStockDetailNonBiasCondition.sampling_method
        }

        if (this.editedStockDetailNonBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/${this.editedStockDetailNonBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/`,
            data
          })
        }

        this.onStockDetailNonBiasConditionClose()
        await this.getStockDetailNonBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockDetailNonBiasClose() {
      this.dialogStockDetailNonBias = false
    },
    async onStockDetailNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Stock Detail Outstanding and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/compute_non_bias/`,
            data: {}
          })
          this.onStockDetailNonBiasClose()
          await this.getStockDetail()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    async onResetStockDetailBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Detail Outstanding. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/reset/`,
            data: {}
          })
          await this.getStockDetail()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async getCombinedInOuts(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/combined-in-out/`,
          params: params,
          hideSuccessAlert: true
        })
        this.combinedInOuts = data.results
        this.combinedInOutsCount = data.count
        this.combinedInOutsSumProductValue = data.sum_product_value ?? 0
        this.combinedInOutsSumInitialValue = data.sum_initial_value ?? 0
        this.combinedInOutsSumActualProductValue =
          data.sum_actual_product_value ?? 0
        this.combinedInOutsSumActualInitialValue =
          data.sum_actual_initial_value ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditCombinedInOut(item) {
      this.editingCombinedInOut = Object.assign({}, item)
      this.dialogCombinedInOut = true
    },
    onCloseCombinedInOut() {
      this.dialogCombinedInOut = false
      this.$nextTick(() => {
        this.editingCombinedInOut = {}
      })
      this.loading = false
    },
    onDeleteCombinedInOut(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/combined-in-out/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.combinedInOutSelected = []
          this.getCombinedInOuts()
        })
      })
      this.loading = false
    },
    async onSaveCombinedInOut() {
      try {
        this.loading = true
        const data = {
          product_code: this.editingCombinedInOut.product_code,
          product_name: this.editingCombinedInOut.product_name,
          product_barcode: this.editingCombinedInOut.product_barcode,
          product_category: this.editingCombinedInOut.product_category,
          product_unit: this.editingCombinedInOut.product_unit,
          doc_no: this.editingCombinedInOut.doc_no,
          doc_date: this.editingCombinedInOut.doc_date,
          doc_description: this.editingCombinedInOut.doc_description,
          vendor_code: this.editingCombinedInOut.vendor_code,
          vendor_name: this.editingCombinedInOut.vendor_name,
          vendor_branch: this.editingCombinedInOut.vendor_branch,
          product_price: this.editingCombinedInOut.product_price,
          product_qty: this.editingCombinedInOut.product_qty,
          product_value: this.editingCombinedInOut.product_value,
          initial_value: this.editingCombinedInOut.initial_value,
          actual_product_price: this.editingCombinedInOut.actual_product_price,
          actual_product_qty: this.editingCombinedInOut.actual_product_qty,
          actual_product_value: this.editingCombinedInOut.actual_product_value,
          actual_initial_value: this.editingCombinedInOut.actual_initial_value,
          warehouse: this.editingCombinedInOut.warehouse,
          branch: this.editingCombinedInOut.branch,
          note: this.editingCombinedInOut.note,
          business_unit:
            this.editingCombinedInOut.business_unit?.id ??
            this.editingCombinedInOut.business_unit,
          status: this.editingCombinedInOut.status,
          sampling_type: this.editingCombinedInOut.sampling_type,
          dummy: this.editingCombinedInOut.dummy
        }

        if (this.editingCombinedInOut.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/combined-in-out/${this.editingCombinedInOut.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/`,
            data
          })
        }

        this.onCloseCombinedInOut()
        await this.getCombinedInOuts()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    onImportCombinedInOut() {
      this.debitSumImportForm = {}
      this.dialogCombinedInOutImport = true
    },
    onCloseImportCombinedInOut() {
      this.debitSumImportForm = {}
      this.dialogCombinedInOutImport = false
    },
    onCombinedInOutImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempCombinedInOutImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 21) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 21. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempCombinedInOutImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  doc_no: row[5],
                  doc_date: DateTime.fromFormat(row[6], 'dd/LL/yyyy').toFormat(
                    'yyyy-LL-dd'
                  ),
                  doc_description: row[7],
                  vendor_code: row[8],
                  vendor_name: row[9],
                  vendor_branch: row[10],
                  product_price: row[11]?.trim().replace(/[,-]/g, ''),
                  product_qty: row[12]?.trim().replace(/[,-]/g, ''),
                  product_value: row[13]?.trim().replace(/[,-]/g, ''),
                  initial_value: row[14]?.trim().replace(/[,-]/g, ''),
                  warehouse: row[15],
                  branch: row[16],
                  business_unit: row[17],
                  note: row[18],
                  status: row[19].trim(),
                  sampling_type: null,
                  dummy: row[20]
                })
              }
            }
          })
        } else {
          this.tempCombinedInOutImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 21) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 21. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempCombinedInOutImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  doc_no: row[5],
                  doc_date: row[6]
                    ? DateTime.fromJSDate(row[6]).toFormat('yyyy-LL-dd')
                    : null,
                  doc_description: row[7],
                  vendor_code: row[8],
                  vendor_name: row[9],
                  vendor_branch: row[10],
                  product_price: row[11],
                  product_qty: row[12],
                  product_value: row[13],
                  initial_value: row[14],
                  warehouse: row[15],
                  branch: row[16],
                  business_unit: row[17],
                  note: row[18],
                  status: row[19].trim(),
                  sampling_type: null,
                  dummy: row[20]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportCombinedInOutDo() {
      try {
        this.loading = true
        const data = this.tempCombinedInOutImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/combined-in-out/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportCombinedInOut()
        await this.getCombinedInOuts()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },

    async getCombinedInOutBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/combined-in-out-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.combinedInOutBiases = data.results
        this.combinedInOutBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCombinedInOutBiasClick() {
      this.dialogCombinedInOutBias = true
    },
    onEditCombinedInOutBias(item) {
      this.editingCombinedInOutBias = Object.assign({}, item)
      this.dialogEditCombinedInOutBias = true
    },
    async onDeleteCombinedInOutBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/combined-in-out-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getCombinedInOutBiases()
        })
      })
      this.loading = false
    },
    onCombinedInOutBiasClose() {
      this.dialogCombinedInOutBias = false
    },
    async onCombinedInOutBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Detail. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/compute_bias/`,
            data: {}
          })
          this.onCombinedInOutBiasClose()
          await this.getCombinedInOuts()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditCombinedInOutBiasClose() {
      this.dialogEditCombinedInOutBias = false
    },
    async onEditCombinedInOutBiasSave() {
      try {
        this.loading = true
        const data = {
          field: this.editingCombinedInOutBias.field,
          operator: this.editingCombinedInOutBias.operator,
          value1: this.editingCombinedInOutBias.value1,
          reason: this.editingCombinedInOutBias.reason
        }
        if (
          this.editingCombinedInOutBias.operator === 'Between' ||
          this.editingCombinedInOutBias.operator === 'Not Between'
        ) {
          data.value2 = this.editingCombinedInOutBias.value2
        }

        if (this.editingCombinedInOutBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/combined-in-out-bias/${this.editingCombinedInOutBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out-bias/`,
            data
          })
        }

        this.onEditCombinedInOutBiasClose()
        await this.getCombinedInOutBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onResetCombinedInOutBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Combined In and Out. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/reset/`,
            data: {}
          })
          await this.getCombinedInOuts()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    async getCombinedInOutNonBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.combinedInOutNonBiases = data.results
        this.combinedInOutNonBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCombinedInOutNonBiasClick() {
      this.dialogCombinedInOutNonBias = true
    },
    onEditCombinedInOutNonBias(item) {
      this.editingCombinedInOutNonBias = Object.assign({}, item)
      this.dialogEditCombinedInOutNonBias = true
    },
    async onDeleteCombinedInOutNonBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getCombinedInOutNonBiases()
        })
      })
      this.loading = false
    },
    onCombinedInOutNonBiasClose() {
      this.dialogCombinedInOutNonBias = false
    },
    async onCombinedInOutNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Combined In and Out and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/compute_non_bias/`,
            data: {}
          })
          this.onCombinedInOutNonBiasClose()
          await this.getCombinedInOuts()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditCombinedInOutNonBiasClose() {
      this.dialogEditCombinedInOutNonBias = false
    },
    async onEditCombinedInOutNonBiasSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editingCombinedInOutNonBias.sampling_size,
          sampling_method: this.editingCombinedInOutNonBias.sampling_method
        }

        if (this.editingCombinedInOutNonBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/${this.editingCombinedInOutNonBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/`,
            data
          })
        }

        this.onEditCombinedInOutNonBiasClose()
        await this.getCombinedInOutNonBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditQuestionnaireLine(line) {
      if (line) {
        this.editingQuestionnaireLine = line
      } else {
        this.editingQuestionnaireLine = Object.assign({
          choice: '',
          points: ''
        })
      }
      this.dialogQuestionnaireLine = true
    },
    onDeleteQuestionnaireLine(items) {
      for (const item of items) {
        const i = this.editingQuestionnaire.lines.findIndex(
          (line) => line === item
        )
        if (i >= 0) {
          this.editingQuestionnaire.lines.splice(i, 1)
        }
      }

      this.updateTotalPoints()
    },
    async onSaveQuestionnaireLine() {
      const i = this.editingQuestionnaire.lines.findIndex(
        (line) => line === this.editingQuestionnaireLine
      )
      if (i >= 0) {
        this.editingQuestionnaire.lines[i] = this.editingQuestionnaireLine
      } else {
        this.editingQuestionnaire.lines.push(this.editingQuestionnaireLine)
      }

      this.updateTotalPoints()
      this.onCloseQuestionnaireLine()
      return
    },
    onCloseQuestionnaireLine() {
      this.dialogQuestionnaireLine = false
      this.$nextTick(() => {
        this.editingQuestionnaireLine = {}
      })
    },

    updateTotalPoints() {
      let totalPoints = 0

      if (this.editedItem.question_type === 'MultipleChoice') {
        // Get the maximum point value
        totalPoints = Math.max(
          ...this.editingQuestionnaire.lines.map((line) => line.points)
        )
      } else if (this.editedItem.question_type === 'Checkbox') {
        // Sum the point values
        totalPoints = this.editingQuestionnaire.lines.reduce((sum, line) => {
          return sum + line.points
        }, 0)
      }

      this.editedItem.total_points = totalPoints
    },
    onViewQuestionAnswer(item) {
      this.editedItem.text_answer = ''
      this.editedItem.answerScore = 0
      this.selectedItem = []
      this.editedItem.questionAnswerDrop = null
      this.taskQuestionnaireId = item.id
      this.taskQuestionnaireType = item.question_type
      if (
        this.taskQuestionnaireId !== null &&
        this.taskQuestionnaireId !== undefined
      ) {
        this.questionLineUrl =
          `task/task-questionnaire-line?task_questionnaire_id_id=` +
          this.taskQuestionnaireId
      }

      this.getCheckboxItem()
      this.dialogQuestionAnswer = true
    },
    closeQuestionAnswer() {
      this.editedItem = {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      }
      this.selectedItem = []
      this.editedItem.questionAnswerDrop = null
      this.apiMethod = 'post'
      this.dialogQuestionAnswer = false
    },
    getQuestionItemKey() {
      if (this.taskQuestionnaireType === 'Text') {
        return []
      }
      if (this.selectedItem.length > 0) {
        return this.selectedItem.map((item) => item.id)
      }

      if (this.editedItem.questionAnswer) {
        if (this.apiMethod === 'put') {
          return [this.editedItem.questionAnswer.id]
        } else {
          return [this.editedItem.questionAnswer.id]
        }
      }
    },
    saveQuestionAnswer() {
      this.loading = true

      const data = {
        task_questionnaire_id: this.taskQuestionnaireId,
        text_answer: this.editedItem.text_answer,
        selected_task_questionnaire: this.getQuestionItemKey(),
        score: this.editedItem.answerScore
      }

      this.$api({
        method: this.apiMethod,
        url: `task/task-questionnaire-answer/${
          this.editedItem.id ? this.editedItem.id + '/' : ''
        }`,
        data
      })

      this.editedItem = {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      }
      this.selectedItem = []
      this.editedItem.questionAnswerDrop = null
      this.dialogQuestionAnswer = false
      this.apiMethod = 'post'
      this.loading = false
    },
    async getCheckboxItem() {
      const { data } = await this.$api({
        method: 'get',
        url:
          `task/task-questionnaire-line?task_questionnaire_id_id=` +
          this.taskQuestionnaireId,
        hideSuccessAlert: true
      })
      this.answerItem = data.results

      await this.$api({
        method: 'get',
        url:
          `task/task-questionnaire-answer?task_questionnaire_id_id=` +
          this.taskQuestionnaireId,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.taskQuestionnaireAnswer = data.results
        this.editedItem.text_answer = data.results[0].text_answer
        this.editedItem.answerScore = data.results[0].score
        this.editedItem.id = data.results[0].id
        this.apiMethod = 'put'
      })

      if (this.computedSelectedItems.length > 0) {
        if (this.taskQuestionnaireType === 'Checkbox') {
          this.selectedItem = this.computedSelectedItems
        }
        if (this.taskQuestionnaireType === 'MultipleChoice') {
          this.editedItem.questionAnswer = this.computedSelectedItems[0]
        }
      }
    },
    sumPointsItem(key) {
      // sum data in give key (property)
      return this.taskQuestionnaireItems.reduce(
        (a, b) => parseFloat(a) + parseFloat(b[key] || 0),
        parseFloat(0)
      )
    },
    sumScoreItem() {
      // sum data in give key (property)
      return this.taskQuestionnaireItems.reduce((acc, result) => {
        if (result.answers && result.answers.score) {
          return acc + result.answers.score
        }
        return acc
      }, 0)
    },
    totalScore() {
      // Use reduce to sum up the points in selectedItem
      if (this.taskQuestionnaireType === 'Checkbox') {
        if (this.selectedItem.length > 0) {
          this.editedItem.answerScore = this.selectedItem.reduce(
            (acc, value) => acc + value.points,
            0
          )
        }
      }
      if (this.taskQuestionnaireType === 'MultipleChoice') {
        if (this.editedItem.questionAnswer) {
          this.editedItem.answerScore = this.editedItem.questionAnswer.points
        }
      }
      return this.editedItem.answerScore
    },

    async getStockValue(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockValueItemItems = data.results
        this.stockValueItemServerItemsLength = data.count
        this.stockValueSumProductValue = data.sum_product_value ?? 0
        this.stockValueSumActualProductValue =
          data.sum_actual_product_value ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockValue(item) {
      this.editedStockValue = Object.assign({}, item)
      this.dialogStockValue = true
    },
    onCloseStockValue() {
      this.dialogStockValue = false
      this.$nextTick(() => {
        this.editedStockValue = {}
      })
      this.loading = false
    },
    onDeleteStockValue(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.stockValueSelected = []
          this.getStockValue()
        })
      })
      this.loading = false
    },
    async onSaveStockValue() {
      try {
        this.loading = true
        const data = {
          warehouse: this.editedStockValue.warehouse,
          branch:
            this.editedStockValue.branch?.id ?? this.editedStockValue.branch,
          business_unit:
            this.editedStockValue.business_unit?.id ??
            this.editedStockValue.business_unit,
          product_value: this.editedStockValue.product_value,
          actual_product_value: this.editedStockValue.actual_product_value,
          note: this.editedStockValue.note,
          status: this.editedStockValue.status,
          sampling_type: this.editedStockValue.sampling_type,
          dummy: this.editedStockValue.dummy
        }

        if (this.editedStockValue.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/${this.editedStockValue.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/`,
            data
          })
        }
        this.onCloseStockValue()
        await this.getStockValue()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onImportStockValue() {
      this.stockValueImportForm = {}
      this.dialogStockValueImport = true
    },
    onStockValueImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempStockValueImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 8) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 8. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempStockValueImports.push({
                  warehouse: row[0],
                  branch: row[1],
                  business_unit: row[2],
                  product_value: row[3]?.trim().replace(/[,-]/g, ''),
                  actual_product_value: row[4]?.trim().replace(/[,-]/g, ''),
                  note: row[5],
                  status: row[6]?.trim(),
                  sampling_type: null,
                  dummy: row[7]
                })
              }
            }
          })
        } else {
          this.tempStockValueImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 8) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 8. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempStockValueImports.push({
                  warehouse: row[0],
                  branch: row[1],
                  business_unit: row[2],
                  product_value: row[3],
                  actual_product_value: row[4],
                  note: row[5],
                  status: row[6],
                  sampling_type: null,
                  dummy: row[7]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportStockValueDo() {
      try {
        this.loading = true
        const data = this.tempStockValueImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportStockValue()
        await this.getStockValue()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onCloseImportStockValue() {
      this.stockValueImportForm = {}
      this.dialogStockValueImport = false
    },
    onStockValueBiasClick() {
      this.dialogStockValueBias = true
    },
    async getStockValueBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockValueBiasItemItems = data.results
        this.stockValueBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockValueBiasCondition(item) {
      this.editedStockValueBiasCondition = Object.assign({}, item)
      this.dialogStockValueBiasCondition = true
    },
    async onDeleteStockValueBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockValueBiasItems()
        })
      })
      this.loading = false
    },
    onStockValueBiasConditionClose() {
      this.dialogStockValueBiasCondition = false
    },
    async onStockValueBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          field: this.editedStockValueBiasCondition.field,
          operator: this.editedStockValueBiasCondition.operator,
          value1: this.editedStockValueBiasCondition.value1,
          reason: this.editedStockValueBiasCondition.reason
        }
        if (this.editedStockValueBiasCondition.operator === 'Between') {
          data.value2 = this.editedStockValueBiasCondition.value2
        }
        if (this.editedStockValueBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/${this.editedStockValueBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/`,
            data
          })
        }
        this.onStockValueBiasConditionClose()
        await this.getStockValueBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockValueBiasClose() {
      this.dialogStockValueBias = false
    },
    async onStockValueBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Value Outstanding. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/compute_bias/`,
            data: {}
          })
          this.onStockValueBiasClose()
          await this.getStockValue()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onStockValueNonBiasClick() {
      this.dialogStockValueNonBias = true
    },
    async getStockValueNonBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockValueNonBiasItemItems = data.results
        this.stockValueNonBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockValueNonBiasCondition(item) {
      this.editedStockValueNonBiasCondition = Object.assign({}, item)
      this.dialogStockValueNonBiasCondition = true
    },
    async onDeleteStockValueNonBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockValueNonBiasItems()
        })
      })
      this.loading = false
    },
    onStockValueNonBiasConditionClose() {
      this.dialogStockValueNonBiasCondition = false
    },
    async onStockValueNonBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editedStockValueNonBiasCondition.sampling_size,
          sampling_method: this.editedStockValueNonBiasCondition.sampling_method
        }

        if (this.editedStockValueNonBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/${this.editedStockValueNonBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/`,
            data
          })
        }

        this.onStockValueNonBiasConditionClose()
        await this.getStockValueNonBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockValueNonBiasClose() {
      this.dialogStockValueNonBias = false
    },
    async onStockValueNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Stock Value Outstanding and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/compute_non_bias/`,
            data: {}
          })
          this.onStockValueNonBiasClose()
          await this.getStockValue()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async onResetStockValueBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Value Outstanding. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/reset/`,
            data: {}
          })
          await this.getStockValue()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async getManDays(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/standard-man-day/`,
          params: params,
          hideSuccessAlert: true
        })
        this.manDaysItemsCount = data.count
        this.manDaysItems = data.results
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditManDay(item) {
      this.editingManDay = Object.assign({}, item)
      if (this.editingManDay.man_day) {
        this.editingManDay.man_day = this.timeHrsAutoConvert(
          this.editingManDay.man_day
        )
      }
      this.dialogManDay = true
    },
    onCloseManDay() {
      this.dialogManDay = false
      this.$nextTick(() => {
        this.editingManDay = {}
      })
      this.loading = false
    },
    onDeleteManDay(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/standard-man-day/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.manDaySelected = []
          this.getManDays()
        })
      })
      this.loading = false
    },
    async onSaveManDay() {
      if (this.editingManDay.position) {
        this.loading = true
        const data = {}
        data.task = this.$route.params.id
        data.position =
          this.editingManDay.position.id ?? this.editingManDay.position
        data.man_day = this.timeHrsAutoConvert(this.editingManDay.man_day, true)
        data.note = this.editingManDay.note

        await this.$api({
          method: this.editingManDay.id ? 'put' : 'post',
          url: this.editingManDay.id
            ? `task/task/${this.$route.params.id}/standard-man-day/${this.editingManDay.id}/`
            : `task/task/${this.$route.params.id}/standard-man-day/`,
          data
        })
        this.editingManDay = {}
        this.onCloseManDay()
        this.getManDays()
      }
    },
    async onTaskTemplateChange(val) {
      const after = val
      const before = this.currentTaskTemplateHeader
      if (after !== null && after.id !== before?.id) {
        if (before != null) {
          const answer = await this.$alert('custom', {
            icon: 'warning',
            title: this.$t('Are you sure?'),
            text: this.$t(
              'This action will delete all your existing Standard man day.'
            ),
            showCancelButton: true
          })
          if (!answer.value) {
            this.formData.task_template_header_id = before
            this.$refs.task_template_header.setDefaultServerItems(
              this.formData.task_template_header_id
            )
            return
          }
        }
        if (this.$route.params.id) {
          this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/standard-man-day/copy_from_task_template/?task_template_id=${after.id}`
          }).then(() => {
            this.currentTaskTemplateHeader = val
            this.getManDays()
          })
        }
      }
    },
    async getRelatedCOAItemItems(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/`,
          hideSuccessAlert: true,
          params: params
        })
        this.relatedCOAItemItems = data.results
        this.relatedCOAItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteRelatedCOAItemItem(item) {
      this.onDeleteRelatedCOAItem(item)
    },
    onDeleteRelatedCOAItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.relatedCOASelected = []
          this.getRelatedCOAItemItems()
        })
      })
      this.loading = false
    },
    closeRelatedCOAItem() {
      this.dialogRelatedCOAItem = false
      this.$nextTick(() => {
        this.editedRelatedCOA = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
    },
    async saveRelatedCOAItem() {
      try {
        this.loading = true
        const data = {
          chart_of_account_detail_id:
            this.editedRelatedCOA.chart_of_account_detail_id.id
        }
        if (this.editedRelatedCOA.id) {
          await this.$api({
            method: 'put',
            url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/${this.editedRelatedCOA.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/`,
            data
          })
        }
        this.closeRelatedCOAItem()
        await this.getRelatedCOAItemItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedCOAItem(item) {
      this.editedRelatedCOA = Object.assign({}, item)
      this.dialogRelatedCOAItem = true
    },
    async getSumRelatedCOAItemItems(options = null) {
      this.loading = true
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      try {
        options.sortBy = ['sequence', 'reference']
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/summarise`,
          params: params,
          hideSuccessAlert: true
        })
        this.sumRelatedCOAItemItems = data.results
        this.sumRelatedCOAItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getSubSumRelatedCOAItemItems(item, value) {
      if (!value) return
      this.loading = true
      try {
        const options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['sequence', 'reference']
        }
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/summarise_detail?id=${item.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.subSumRelatedCOAItemItems[item.id] = data.results
        this.subSumRelatedCOAItemServerItemsLength[item.id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
